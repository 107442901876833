import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import logo from '../../assets/images/workflow_logo.png';
import styles from '../../styles/components/Navbars/NavbarAddWorkflow.module.css';

const NavbarAddWorkflow = ({
  setShowOriginalNavbar,
  showOriginalNavbar,
  onPublish,
  currentTab,
  onTabChange,
}) => {
  const navigate = useNavigate();
  const [lastScrollY, setLastScrollY] = useState(0);
  const [constantNavbarTop, setConstantNavbarTop] = useState(false);

  const navigateToDashboard = () => {
    navigate('/admin-dashboard');
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setShowOriginalNavbar(true);
        setConstantNavbarTop(false);
      } else if (window.scrollY > lastScrollY) {
        setShowOriginalNavbar(false);
        setConstantNavbarTop(true);
      }
      setLastScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY, setShowOriginalNavbar]);

  return (
    <div>
      {/* Original Navbar */}
      <div
        className={`${styles.originalNavbar} ${showOriginalNavbar ? styles.show : styles.hide}`}
      >
        <div className={styles.logoSection} onClick={navigateToDashboard}>
          <img src={logo} alt="WorkflowMaster Logo" className={styles.logo} />
          <span className={styles.logoText}>WorkflowMaster</span>
          <div
            className={styles.workflowBuilderText}
            onClick={(e) => e.stopPropagation()}
          >
            <i className="fa fa-sitemap" /> {/* Example Icon */}
            Workflow Builder
          </div>
        </div>

        <div className={styles.rightSection}>
          <button className={styles.navButton}>
            <i className="fa fa-question-circle" /> Help
          </button>
          <button onClick={onPublish} className={styles.publishButton}>
            <i className="fa fa-check" />
            Publish
          </button>
        </div>
      </div>

      {/* Constant Navbar for Tabs */}
      <div
        className={`${styles.fixedNavbar} ${
          constantNavbarTop ? styles.topPosition : ''
        }`}
      >
        <button
          className={`${styles.navTab} ${
            currentTab === 'build' ? styles.activeTab : ''
          }`}
          onClick={() => onTabChange('build')}
        >
          <i className="fa fa-wrench" />
          Build
        </button>
        <button
          className={`${styles.navTab} ${
            currentTab === 'settings' ? styles.activeTab : ''
          }`}
          onClick={() => onTabChange('settings')}
        >
          <i className="fa fa-cog" />
          Settings
        </button>
        <button
          className={`${styles.navTab} ${
            currentTab === 'dependencies' ? styles.activeTab : ''
          }`}
          onClick={() => onTabChange('dependencies')}
        >
          <i className="fa fa-project-diagram" />
          Dependencies
        </button>
      </div>
    </div>
  );
};

export default NavbarAddWorkflow;
