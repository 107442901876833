import React, { useState, useEffect } from 'react';
import styles from '../../styles/components/FieldTypes/FieldTypes.module.css';
import { FaCalendarAlt } from 'react-icons/fa'; // Import a calendar icon
import { calculateLabelWidth } from './FieldTypesUtils';


// List of configuration keys for DateField
export const DateFieldConfig = [
  "fieldLabel",
  "required",
  "help_text",
  "min_date",
  "max_date",
  "default_value_date",
];

function DateField({ field, onConfigChange, isSelected }) {
  const [label, setLabel] = useState(field.config.fieldLabel || "");
  const [helpText, setHelpText] = useState(field.config.help_text || "");
  const [minDate, setMinDate] = useState(field.config.min_date || "");
  const [maxDate, setMaxDate] = useState(field.config.max_date || "");
  const [defaultDate, setDefaultDate] = useState(field.config.default_value_date || "");
  const isRequired = field.config.required;

  useEffect(() => {
    setLabel(field.config.fieldLabel || "");
    setHelpText(field.config.help_text || "");
    setMinDate(field.config.min_date || "");
    setMaxDate(field.config.max_date || "");
    setDefaultDate(field.config.default_value_date || "");

    const labelWidth = calculateLabelWidth(field.config.fieldLabel);
    document.documentElement.style.setProperty(
      `--label-length-${field.id}`,
      labelWidth / 8
    );
  }, [field.config]);

  // Handle field label change
  const handleLabelChange = (e) => {
    const updatedConfig = { ...field.config, fieldLabel: e.target.value };
    onConfigChange(updatedConfig);
  };

  // Handle help text change
  const handleHelpTextChange = (e) => {
    const updatedConfig = { ...field.config, help_text: e.target.value };
    onConfigChange(updatedConfig);
  };

  return (
    <div className={styles.dateField}>
      {/* Label Section */}
      <div className={styles.labelWrapper}>
        <div className={styles.labelContainer}>
          <textarea
            className={`${styles.fieldLabel} ${isRequired ? styles.required : ''}`}
            value={label}
            placeholder={isSelected ? "Type a label" : ""}
            onChange={handleLabelChange}
            rows="1"
          />
          {isRequired && (
            <span
              style={{
                left: `max(min(calc(10px + 1ch * var(--label-length-${field.id})), calc(100% - 40px)), calc(14%))`,
                top: '5px',
                position: 'absolute',
                color: 'red',
              }}
            >
              *
            </span>
          )}
        </div>
      </div>

      {/* Date Input Section */}
      <div className={styles.dateInputWrapper}>
        <FaCalendarAlt className={styles.calendarIcon} />
        <input
          className={styles.dateInput}
          type="date"
          min={minDate}
          max={maxDate}
          defaultValue={defaultDate}
          disabled
        />
      </div>

      {/* Editable Help Text Section */}
      <textarea
        className={styles.helpText}
        value={helpText}
        placeholder={isSelected ? "Type a help text" : ""}
        onChange={handleHelpTextChange}
        rows="1"
      />
    </div>
  );
}


export default DateField;
