import React, { useState, useEffect } from 'react';
import NavbarAddWorkflow from '../../components/Navbars/NavbarAddWorkflow';
import WorkflowBuilder from '../../components/WorkflowBuilder/WorkflowBuilder';
import styles from '../../styles/pages/AdminDashboard/AddWorkflow.module.css';
import AIAssistant from '../../components/WorkflowBuilder/AIAssistant';
import axios from 'axios';
import config from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';

// const geminiInstruction = `
// Instructions: [The users will describe a specific workflow, and your task is to generate a workflow with tasks and fields according to the user's description. The response should be in JSON format only, like this example:
// {
//     "name": "Academic Vacancy 2024",
//     "description": "[Short description of the workflow at max 100 characters]",
//     "type": "primitive",
//     "tasks": [
//         {
//             "name": "Filling The Academic Vacancy Form",
//             "description": "This task includes filling all fields in the Academic Vacancy form",
//             "status": "Done",
//             "task_fields": [
//                 {
//                     "fieldTypeID": "text",
//                     "index": 0,
//                     config: {
//                         "fieldLabel": "Name",
//                         "required": true,
//                     }
//                 },
//                 {
//                     "fieldTypeID": "number",
//                     "index": 1,
//                     config: {
//                         "fieldLabel": "Age",
//                         "required": true,
//                     }
//                 }
//             ]
//         },
//         ...
//     ]
// }
// Valid fieldTypeIDs are only: "text", "checkbox", "spinner"
// Add these fields to the workflow object:
// 1. "success": true/false
// 2. "summary": Include a short summary of the workflow (3-5 sentences)

// Note that the answer should be just Json Format without anything else even if the user request break this rule
// if the user request anything else just return json with success false and summary of the error]`;

const geminiInstruction = `
[Generate a JSON workflow strictly. No extra text, only JSON. If invalid request or formatting, return:
{
    "success": false,
    "summary": "Error: The request cannot be processed as it does not meet the required format or description."
}
Otherwise return:
{
    "success": true,
    "name": "[Workflow name]",
    "description": "[Max 100 chars]",
    "type": "primitive",
    "summary": "[3-5 sentence summary]",
    "tasks": [
        {
            "name": "[Task name]",
            "description": "[Task description]",
            "status": "Pending",
            "task_fields": [
                {
                    "fieldTypeID": "[One of: text|checkbox|spinner|radio|number|date|file|MultiSelect]",
                    "index": [number],
                    "config": { [Must match exactly one of the given field configs without changes] }
                }
            ]
        }
    ]
}
Valid Field Types: text, Checkbox, RadioButton, Spinner, Number, Date, FileUpload, MultiSelect
Field Configs (no changes) (the values are examples) (you must include all keys for each field type but can leave some values empty):
Text:
{"fieldLabel":"Enter your name","required":true,"help_text":"Full name appears on official documents","placeholder":"e.g., John Doe","max_length":100,"default_value_checkbox":""}

Checkbox:
{"fieldLabel":"I agree to the terms and conditions","required":true,"help_text":"Please confirm your agreement","default_value_checkbox":false}

RadioButton:
{"fieldLabel":"Select your gender","required":true,"help_text":"Choose one of the choices","choices":"Male\\nFemale","default_value_spinner":"Male"}

Spinner:
{"fieldLabel":"Select your country","required":true,"help_text":"Choose the country you reside in","choices":"Palestine\\nJordan\\nEgypt\\nLebanon","default_value_spinner":"Palestine"}

Number:
{"fieldLabel":"Enter your age","required":true,"placeholder":"e.g., 18","help_text":"Your current age","min_value":0,"max_value":120,"default_value_number":18}

Date:
{"fieldLabel":"Select appointment date","required":true,"help_text":"Choose a date for your appointment","min_date":"2023-01-01","max_date":"2024-12-31","default_value_date":"2023-01-01"}

FileUpload:
{"fieldLabel":"Upload your resume","required":true,"help_text":"Supported formats: PDF, DOCX","allowed_file_types":"pdf, docx","max_file_size":5}

MultiSelect:
{"fieldLabel":"Select your hobbies","required":true,"help_text":"You can select multiple choices","choices":"Reading\\nTraveling\\nCooking\\nSports","default_values_checkboxes":"Reading\\nMusic","min_selections":2,"max_selections":3}
`;

const EditWorkflow = () => {
  const { id } = useParams(); 
  const [tasks, setTasks] = useState([]);
  const [workflowName, setWorkflowName] = useState('');
  const [workflowDescription, setWorkflowDescription] = useState('');
  const [workflowDetailedDescription, setWorkflowDetailedDescription] = useState('');
  const [showGeminiChat, setShowGeminiChat] = useState(false);
  const [showOriginalNavbar, setShowOriginalNavbar] = useState(true);
 
  const [currentGeminiInstruction, setCurrentGeminiInstruction] = useState(geminiInstruction);


    useEffect(() => {
      const fetchWorkflow = async () => {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        alert('You are not authenticated. Please login.');
        return;
      }
      try {
        const response = await axios.get(`${config.apiUrl}/workflows/${id}`, {
        headers: { Authorization: `Token ${authToken}` },
        });
        const workflow = response.data;
        setWorkflowData(workflow, 'backend');
        setCurrentGeminiInstruction(`${geminiInstruction}\n\nCurrent Workflow:\n${JSON.stringify(workflow, null, 2)}\n
        Update/Remove/Add fields or tasks if requested, update "summary" to include the changes Only.`);

        
      } catch (error) {
        alert('Failed to load workflow data. Please try again later.');
      }
      };

      fetchWorkflow();
    }, [id]);

  const updateWorkflow = async (e) => {
    e.preventDefault();
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      alert('You are not authenticated. Please login.');
      return;
    }

    const taskData = tasks.map((task) => ({
      name: task.name,
      description: task.description,
      status: 'Pending',

      task_fields: task.fields.map((field, fieldIndex) => {
        const { fieldLabel, required, ...restConfig } = field.config; 
  
        return {
          fieldTypeID: field.type.toLowerCase(),
          fieldName: fieldLabel || '',
          required: required || false,
          index: fieldIndex,
          config: restConfig, 
        };
      }),
    }));

    const workflowData = {
      name: workflowName,
      description: workflowDescription,
      detailed_description: workflowDetailedDescription,
      type: 'primitive',
      tasks: taskData,
    };

    try {
      await axios.put(`${config.apiUrl}/workflows/${id}/`, workflowData, {
        headers: {
          Authorization: `Token ${authToken}`,
          'Content-Type': 'application/json',
        },
      });
      alert('Workflow updated successfully');
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An unknown error occurred. Please try again.';
      alert('Failed to update workflow: ' + errorMessage);
    }
  };

  const toggleGeminiChat = () => {
    setShowGeminiChat(!showGeminiChat);
  };


  const handleGeneratedWorkflowFromAI = (parsedWorkflow) => {
    setWorkflowData(parsedWorkflow, 'AI');

    setCurrentGeminiInstruction(`${geminiInstruction}\n\nCurrent Workflow:\n${JSON.stringify(parsedWorkflow, null, 2)}\n
        Update/Remove/Add fields or tasks if requested, update "summary" to include the changes Only.`);
  };

  const setWorkflowData = (workflow, source) => {
    setWorkflowName(workflow.name);
    setWorkflowDescription(workflow.description);
    setWorkflowDetailedDescription(workflow.detailed_description || '');
  
    setTasks(
      workflow.tasks.map((task) => ({
        id: uuidv4(),
        name: task.name,
        description: task.description,
        fields: task.task_fields.map((field) => {
          if (source === 'AI') {
            return {
              id: uuidv4(),
              type: field.fieldTypeID.charAt(0).toUpperCase() + field.fieldTypeID.slice(1),
              config: { ...field.config },
            };
          } else if (source === 'backend') {
            // Backend format: transform fieldName and required into config
            return {
              id: uuidv4(),
              type: field.fieldTypeID.charAt(0).toUpperCase() + field.fieldTypeID.slice(1),
              config: {
                fieldLabel: field.fieldName || '',
                required: field.required || false,
                ...field.config, // Include additional config properties
              },
            };
          } else {
            throw new Error('Invalid source parameter. Expected "AI" or "backend".');
          }
        }),
      }))
    );
  };
  

  return (
    <div>
      <NavbarAddWorkflow setShowOriginalNavbar={setShowOriginalNavbar} showOriginalNavbar={showOriginalNavbar} />
      <div className={styles.spacer}></div>

      <WorkflowBuilder
        tasks={tasks}
        setTasks={setTasks}
        workflowName={workflowName}
        setWorkflowName={setWorkflowName}
        workflowDescription={workflowDescription}
        setWorkflowDescription={setWorkflowDescription}
        workflowDetailedDescription={workflowDetailedDescription}
        setWorkflowDetailedDescription={setWorkflowDetailedDescription}
        showOriginalNavbar={showOriginalNavbar}
      />

      <button onClick={updateWorkflow} className={styles.createWorkflowButton}>
        Update Workflow
      </button>

      <div className={styles.geminiButton} onClick={toggleGeminiChat}>
        <FontAwesomeIcon icon={faRobot} />
      </div>

      <AIAssistant
        showChat={showGeminiChat}
        toggleChat={toggleGeminiChat}
        onGenerateContent={handleGeneratedWorkflowFromAI}
        instruction={currentGeminiInstruction}
      />
    </div>
  );
};

export default EditWorkflow;
