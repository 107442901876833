import React, { useState, useRef, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { 
  FaPenFancy, 
  FaUpload, 
  FaEraser, 
  FaPaintBrush, 
  FaFont, 
  FaImage 
} from 'react-icons/fa';
import * as ReactTooltip from 'react-tooltip';
import styles from '../../styles/components/FieldTypesInput/SignatureInput.module.css';



const SignatureInput = ({
  required,
  helpText,
  currentValue,
  onChange,
  isSaving,
  success,
  error,
  tooltip,
  itemId
}) => {
  // Tabs (always: draw, type, upload).
  // If you want to optionally disable some, remove them from this array.
  const tabs = [
    { name: 'draw', label: 'Draw', icon: <FaPaintBrush /> },
    { name: 'type', label: 'Type', icon: <FaFont /> },
    { name: 'upload', label: 'Upload', icon: <FaImage /> },
  ];

  // Active tab state
  const [activeTab, setActiveTab] = useState('draw');

  // Refs & state for each tab
  const sigCanvasRef = useRef(null);          // for Draw
  const [typedSignature, setTypedSignature] = useState('');  // for Type
  const [uploadFile, setUploadFile] = useState(null);         // for Upload (raw file)
  const [uploadedPreview, setUploadedPreview] = useState(null); // base64 preview

  // A small selection of "handwritten" style fonts for the typed preview
  const fontOptions = [
    { label: 'Great Vibes', value: '"Great Vibes", cursive' },
    { label: 'Dancing Script', value: '"Dancing Script", cursive' },
    { label: 'Satisfy', value: '"Satisfy", cursive' },
    { label: 'Arizonia', value: '"Arizonia", cursive' },
    { label: 'Parisienne', value: '"Parisienne", cursive' },
    { label: 'Shadows Into Light', value: '"Shadows Into Light", cursive' },
  ];
  const [selectedFont, setSelectedFont] = useState(fontOptions[0].value);

  /**
   * Whenever a user switches tabs, we clear the data from the tab they left
   * so that data doesn't persist across tabs. 
   */
  const handleTabChange = (tabName) => {
    // Clear old tab’s data
    if (activeTab === 'draw') {
      if (sigCanvasRef.current) {
        sigCanvasRef.current.clear();
      }
    } else if (activeTab === 'type') {
      setTypedSignature('');
    } else if (activeTab === 'upload') {
      setUploadFile(null);
      setUploadedPreview(null);
    }

    // Also call onChange(null) to "unset" the old tab's data in the parent
    onChange(null);

    // Switch active tab
    setActiveTab(tabName);
  };

  /**
   * Handle the signature pad “draw” tab
   */
  const handleEndSign = () => {
    if (sigCanvasRef.current) {
      const data = sigCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');
      onChange(data); 
    }
  };

  const handleClearDraw = () => {
    if (sigCanvasRef.current) {
      sigCanvasRef.current.clear();
      onChange(null); 
    }
  };

  /**
   * Handle typed signature
   */
  const handleTypedSignatureChange = (val) => {
    setTypedSignature(val);
    // Optionally store typed signature as something like "typed:asdasd"
    // or just pass the text itself:
    onChange(`typed:${val}`);
  };

  const handleFontChange = (e) => {
    setSelectedFont(e.target.value);
  };

  /**
   * Handle uploaded signature
   */
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploadFile(file);
    
    const reader = new FileReader();
    reader.onload = function(evt) {
      const base64 = evt.target.result;
      setUploadedPreview(base64);
      onChange(base64);
    };
    reader.readAsDataURL(file);
  };

  // Clear the upload manually 
  const handleClearUpload = () => {
    setUploadFile(null);
    setUploadedPreview(null);
    onChange(null);
  };

  return (
    <div className={styles.signatureContainer}>
      {/* Optional tooltip */}
      {tooltip && (
        <ReactTooltip id={`${itemId}-tooltip`} place="top" effect="solid">
          {tooltip}
        </ReactTooltip>
      )}

      {/* Header row: "Signature" label, required star, help icon, etc. 
          (Might be handled by the parent, or here if you prefer.)
       */}

      {/* Tabs */}
      <div className={styles.tabHeader}>
        {tabs.map((tab) => (
          <button
            key={tab.name}
            className={`${styles.tabButton} ${activeTab === tab.name ? styles.activeTab : ''}`}
            onClick={() => handleTabChange(tab.name)}
            data-tip={tooltip || ''}
            data-for={`${itemId}-tooltip`}
          >
            {tab.icon} {tab.label}
          </button>
        ))}
      </div>

      {/* Main content area */}
      <div className={styles.tabContent}>
        {activeTab === 'draw' && (
          <div className={styles.drawTab}>
            <div className={styles.signaturePadWrapper}>
              {/* React Signature Canvas */}
              <SignatureCanvas
                ref={sigCanvasRef}
                penColor="black"
                backgroundColor="#fff"
                onEnd={handleEndSign}
                canvasProps={{
                  className: styles.signatureCanvas
                }}
              />
              {/* "Sign Here" overlay if nothing yet drawn */}
              {/* We can detect emptiness via sigCanvasRef.current.isEmpty() 
                  but we have to wait for it to mount. For simplicity, 
                  we always show an overlay initially. */}
              <div className={styles.placeholderOverlay}>
                <FaPenFancy className={styles.penIcon} />
                <span>Sign Here</span>
                <div className={styles.greyLine} />
              </div>

              {/* Clear button inside pad */}
              <button 
                className={styles.clearButton} 
                type="button" 
                onClick={handleClearDraw}
              >
                <FaEraser /> Clear
              </button>
            </div>
          </div>
        )}

        {activeTab === 'type' && (
          <div className={styles.typeTab}>
            {/* User types text */}
            <input
              type="text"
              value={typedSignature}
              onChange={(e) => handleTypedSignatureChange(e.target.value)}
              placeholder="Type your signature"
              className={styles.typedSignatureInput}
            />
            {/* Let the user pick a fancy font */}
            <select
              className={styles.fontSelect}
              onChange={handleFontChange}
              value={selectedFont}
            >
              {fontOptions.map((font, idx) => (
                <option key={idx} value={font.value}>
                  {font.label}
                </option>
              ))}
            </select>

            {/* Show a "signature preview" of typed text in the chosen font */}
            <div className={styles.typedPreview} style={{ fontFamily: selectedFont }}>
              {typedSignature || "Your typed signature preview..."}
            </div>

            <p className={styles.typeNote}>
              (This text will be used as your signature)
            </p>
          </div>
        )}

        {activeTab === 'upload' && (
          <div className={styles.uploadTab}>
            <input type="file" accept="image/*" onChange={handleFileUpload} />
            {uploadedPreview && (
              <div className={styles.uploadPreview}>
                <img src={uploadedPreview} alt="Uploaded Signature" />
              </div>
            )}
            {uploadFile && (
              <button 
                className={styles.clearUploadButton} 
                type="button" 
                onClick={handleClearUpload}
              >
                <FaEraser /> Clear Upload
              </button>
            )}
          </div>
        )}
      </div>



      <div className={styles.stateIcons}>
        {isSaving && <div className={styles.savingSpinner} />}
        {success && <div className={styles.successMark}>✓ Saved</div>}
        {error && <div className={styles.errorMark}>Error: {error}</div>}
      </div>
    </div>
  );
};

export default SignatureInput;
