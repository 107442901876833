import React, { useState, useEffect } from 'react';
import styles from '../../styles/components/FieldTypes/MultiSelectField.module.css';
import { calculateLabelWidth } from './FieldTypesUtils';

// List of configuration keys for MultiSelectField
export const MultiSelectFieldConfig = [
  "fieldLabel",
  "required",
  "help_text",
  "choices",
  "default_values_checkboxes",
  "min_selections",
  "max_selections",
];

function MultiSelectField({ field, onConfigChange, isSelected }) {
  const [label, setLabel] = useState(field.config.fieldLabel || "");
  const [helpText, setHelpText] = useState(field.config.help_text || "");
  const [choices, setChoices] = useState(
    typeof field.config.choices === 'string'
      ? field.config.choices.split('\n')
      : Array.isArray(field.config.choices)
      ? field.config.choices
      : []
  );
  const [defaultValues, setDefaultValues] = useState(field.config.default_values_checkboxes || []);
  const [minSelections, setMinSelections] = useState(field.config.min_selections || 0);
  const [maxSelections, setMaxSelections] = useState(field.config.max_selections || choices.length);
  const isRequired = field.config.required;

  useEffect(() => {
    setLabel(field.config.fieldLabel || "");
    setHelpText(field.config.help_text || "");
    setChoices(
      typeof field.config.choices === 'string'
        ? field.config.choices.split('\n')
        : Array.isArray(field.config.choices)
        ? field.config.choices
        : []
    );
    setDefaultValues(field.config.default_values_checkboxes || []);

    // check if min_selections is less than 0
    if (field.config && field.config.min_selections < 0) {
      onConfigChange({ ...field.config, min_selections: 0 });
    }

    // check if max_selections is greater than choices.length
    if (field.config && field.config.max_selections > choices.length) {
      onConfigChange({ ...field.config, max_selections: choices.length });
    }

    // check if min_selections is greater than max_selections
    if (field.config && field.config.min_selections > field.config.max_selections) {
      onConfigChange({ ...field.config, min_selections: field.config.max_selections });
    }




    setMinSelections(field.config.min_selections || 0);
    setMaxSelections(field.config.max_selections || choices.length);

    const labelWidth = calculateLabelWidth(field.config.fieldLabel);
    document.documentElement.style.setProperty(
      `--label-length-${field.id}`,
      labelWidth / 8
    );
  }, [field.config]);

  // Handle adding a new choice
  const addChoice = () => {
    const updatedChoices = [...choices, `Choice ${choices.length + 1}`];
    const updatedChoicesString = updatedChoices.join('\n');
    setChoices(updatedChoices);
    onConfigChange({ ...field.config, choices: updatedChoicesString });
  };

  // Handle deleting a choice
  const deleteChoice = (index) => {
    const updatedChoices = choices.filter((_, i) => i !== index);
    const updatedChoicesString = updatedChoices.join('\n');
    setChoices(updatedChoices);

    // check if deleted choice is in defaultValues
    const updatedDefaultValues = defaultValues.filter((_, i) => i !== index);
    setDefaultValues(updatedDefaultValues);

    onConfigChange({ ...field.config, choices: updatedChoicesString, default_values_checkboxes: updatedDefaultValues });
  };

  // Handle changes to individual choices
  const updateChoice = (index, value) => {
    const updatedChoices = [...choices];
    updatedChoices[index] = value;
    const updatedChoicesString = updatedChoices.join('\n');
    setChoices(updatedChoices);
    onConfigChange({ ...field.config, choices: updatedChoicesString });
  };

  // Handle changes to field label
  const handleLabelChange = (e) => {
    const updatedConfig = { ...field.config, fieldLabel: e.target.value };
    onConfigChange(updatedConfig);
  };

  // Handle changes to help text
  const handleHelpTextChange = (e) => {
    const updatedConfig = { ...field.config, help_text: e.target.value };
    onConfigChange(updatedConfig);
  };

  // Ensure minSelections is not greater than maxSelections
  const handleMinChange = (value) => {
    const updatedMin = Math.min(value, maxSelections);
    onConfigChange({ ...field.config, min_selections: updatedMin });
    setMinSelections(updatedMin);
  };

  const handleMaxChange = (value) => {
    const updatedMax = Math.max(value, minSelections);
    onConfigChange({ ...field.config, max_selections: updatedMax });
    setMaxSelections(updatedMax);
  };

  return (
    <div className={styles.multiSelectField}>
      {/* Label Section */}
      <div className={styles.labelWrapper}>
        <div className={styles.labelContainer}>
          <textarea
            className={`${styles.fieldLabel} ${isRequired ? styles.required : ''}`}
            value={label}
            placeholder={isSelected ? "Type a label" : ""}
            onChange={handleLabelChange}
            rows="1"
          />
          {isRequired && (
            <span
              style={{
                left: `max(min(calc(10px + 1ch * var(--label-length-${field.id})), calc(100% - 40px)), calc(14%))`,
                top: '5px',
                position: 'absolute',
                color: 'red',
              }}
            >
              *
            </span>
          )}
        </div>
      </div>

      {/* Checkboxes */}
      <div className={styles.checkboxContainer}>
        {choices.map((choice, index) => (
          <label key={index} className={styles.checkboxLabel}>
            <input
              type="checkbox"
              value={choice}
              checked={defaultValues.includes(choice)}
              disabled
            />
            {choice}
          </label>
        ))}
      </div>

      {/* Editable Help Text Section */}
      <textarea
        className={styles.helpText}
        value={helpText}
        placeholder={isSelected ? "Type a help text" : ""}
        onChange={handleHelpTextChange}
        rows="1"
      />

      {/* Grey Line Separator */}
      {isSelected && <hr className={styles.separator} />}

      {/* Editable Choices */}
      {isSelected && (
        <div className={styles.choicesEditor}>
          {choices.map((choice, index) => (
            <div key={index} className={styles.choiceItem}>
              <input
                type="text"
                value={choice}
                onChange={(e) => updateChoice(index, e.target.value)}
                className={styles.choiceInput}
              />
              <button
                className={styles.deleteChoiceButton}
                onClick={() => deleteChoice(index)}
              >
                Delete
              </button>
            </div>
          ))}
          <button className={styles.addChoiceButton} onClick={addChoice}>
            Add Choice
          </button>
        </div>
      )}

      {/* Min and Max Selections (only shown when selected) */}
      {isSelected && (
        <div className={styles.selectionControls}>
          <div className={styles.selectionControl}>
            <label>Min Selections</label>
            <input
              type="number"
              value={minSelections}
              onChange={(e) => handleMinChange(Number(e.target.value))}
              min="0"
              max={maxSelections}
            />
          </div>
          <div className={styles.selectionControl}>
            <label>Max Selections</label>
            <input
              type="number"
              value={maxSelections}
              onChange={(e) => handleMaxChange(Number(e.target.value))}
              min={minSelections}
              max={choices.length}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default MultiSelectField;
