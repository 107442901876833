import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { FormItem } from "./FormItem";
import styles from '../../styles/components/WorkflowDetails/MultiStepForm.module.css';

export const MultiStepForm = forwardRef(({ task, step, onPageUpdate, workflowId, userTask }, ref) => {
  const [answers, setAnswers] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const initialAnswers = {};
    userTask.user_task_fields.forEach(item => {
      initialAnswers[item.fieldName] = item.fieldValue || '';
    });
    setAnswers(initialAnswers);
  }, [step, userTask]);

  const updateAnswers = (value, fieldName) => {
    const updatedAnswers = { ...answers, [fieldName]: value };
    setAnswers(updatedAnswers);
    try {
      onPageUpdate(step, updatedAnswers);
    } catch (error) {
      console.error('Error updating page:', error);
    }
  };

  const validateFields = () => {
    let isValid = true;
    // If task or fields not loaded yet, no validation
    if (!task || !task.task_fields) return true;

    // For each required field, check if answer is present
    task.task_fields.forEach(item => {
      const config = item.config || {};
      const required = config.required || false;
      const value = answers[item.fieldName] || '';
      if (required && (!value || value.trim() === '')) {
        isValid = false;
      }
    });

    return isValid;
  };

  useImperativeHandle(ref, () => ({
    validateFields
  }));

  return (
    <div className={styles.formContainer}>
      {task && task.task_fields && (
        <div className={styles.fieldsWrapper}>
          {task.task_fields.map((item, index) => (
            <div className={styles.fieldGroup} key={index}>
              <FormItem
                item={item}
                userTask={userTask}
                answer={answers[item.fieldName]}
                onChange={updateAnswers}
                workflowId={workflowId}
                onSavingStateChange={setIsSaving}
              />
            </div>
          ))}
        </div>
      )}

      {isSaving && (
        <div className={styles.savingIndicator}>
          <svg className={styles.spinnerIcon} xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24">
            <circle className={styles.spinnerCircle} cx="12" cy="12" r="10"
              stroke="currentColor" strokeWidth="4"></circle>
            <path className={styles.spinnerPath} fill="currentColor" d="
                    M4 12a8 8 0 018-8v8H4z"></path>
          </svg>
          Saving changes...
        </div>
      )}
    </div>
  );
});
