import React, { useState, useEffect, useRef } from 'react';
import { calculateLabelWidth } from './FieldTypesUtils';
import styles from '../../styles/components/FieldTypes/FieldTypes.module.css';

// Configuration keys for SpinnerField
export const SpinnerFieldConfig = [
  "fieldLabel",
  "required",
  "help_text",
  "choices",
  "default_value_spinner",
];

function SpinnerField({ field, onConfigChange, isSelected }) {
  const [label, setLabel] = useState(field.config.fieldLabel);
  const [choices, setChoices] = useState(field.config.choices || "");
  const [helpText, setHelpText] = useState(field.config.help_text || '');
  const [editingChoices, setEditingChoices] = useState(false); // Toggle textarea for editing choices
  const [isRequired] = useState(field.config.required);
  const [default_value_spinner, setDefault_value_spinner] = useState(field.config.default_value_spinner || '');
  const editorRef = useRef(null);

  // Handle label change and notify WorkflowBuilder
  const handleLabelChange = (e) => {
    const updatedConfig = { ...field.config, fieldLabel: e.target.value };
    setLabel(e.target.value);
    onConfigChange(updatedConfig);
  };

  // Handle choices change and notify WorkflowBuilder
  const handleChoicesChange = (e) => {
    const newChoices = e.target.value;
    setChoices(newChoices);
    const updatedConfig = { ...field.config, choices: newChoices };
    onConfigChange(updatedConfig);
  };

  // Handle help text change
  const handleHelpTextChange = (e) => {
    const updatedConfig = { ...field.config, help_text: e.target.value };
    setHelpText(e.target.value);
    onConfigChange(updatedConfig);
  };

  // Handle clicking outside of the editor
  const handleClickOutside = (event) => {
    if (editorRef.current && !editorRef.current.contains(event.target)) {
      setEditingChoices(false);
    }
  };

  const handleSelectClick = () => {
    setEditingChoices(true); // Show textarea for editing choices
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setLabel(field.config.fieldLabel);
    setChoices(field.config.choices || "");
    setHelpText(field.config.help_text || '');
    setDefault_value_spinner(field.config.default_value_spinner || '');

    const labelWidth = calculateLabelWidth(field.config.fieldLabel);
    document.documentElement.style.setProperty('--label-length-'+field.id, labelWidth/8);


  }, [field.config]);

  return (
    <div className={styles.spinnerField}>
      {/* Editable Label Section */}
      <div className={styles.labelWrapper}>
        <div className={styles.labelContainer}>
          <textarea
            className={`${styles.fieldLabel} ${isRequired ? styles.required : ''}`}
            value={label}
            placeholder={isSelected ? "Type a label" : ""}
            onChange={handleLabelChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.currentTarget.blur();
              }
            }}
            onInput={(e) => {
              e.target.style.height = 'auto';
              e.target.style.height = `${e.target.scrollHeight}px`;
            }}
            rows="1"
            wrap="soft"
          />
          {isRequired && <span 
            style= {{ left: 'max(min(calc(10px + 1ch *var(--label-length-'+field.id+')), calc(100% - 40px)),calc(14%)', top: '5px'
            , position: 'absolute', color: 'red'
            }}
            >
          *</span>}
        </div>
      </div>

      {/* Select Dropdown and Choices Editor */}
      <div ref={editorRef}>
        {editingChoices ? (
          <div>
            <select value={default_value_spinner} disabled className={styles.spinnerInput} onClick={handleSelectClick}>
              <option value="" selected disabled>Select a choice</option>
              {choices.split('\n').map((choice, index) => (
                <option key={index+1} value={choice}>{choice}</option>
              ))}
            </select>
            <textarea
              className={styles.choicesEditor}
              value={choices}
              onChange={handleChoicesChange}
              placeholder="Enter choices, one per line"
              rows={5}
            />
          </div>
        ) : (
          <select  value={default_value_spinner} className={styles.spinnerInput} onClick={handleSelectClick}>
            <option value="" selected disabled>Select a choice</option>
            {choices.split('\n').map((choice, index) => (
              <option key={index+1} value={choice}>{choice}</option>
            ))}
          </select>
        )}
      </div>

      {/* Editable Help Text Section */}
      <textarea
        className={styles.helpText}
        value={helpText}
        placeholder={isSelected ? "Type a help text" : ""}
        onChange={handleHelpTextChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.currentTarget.blur();
          }
        }}
        onInput={(e) => {
          e.target.style.height = 'auto';
          e.target.style.height = `${e.target.scrollHeight}px`;
        }}
        rows="1"
        wrap="soft"
      />
    </div>
  );
}

export default SpinnerField;
