import React, { useState, useRef } from 'react';
import styles from '../../styles/components/WorkflowBuilder/WorkflowSettings.module.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Example logo imports (replace with your own file paths!)
import GoogleSheetsLogo from '../../assets/images/google-sheet.png';
import GoogleDriveLogo from '../../assets/images/google-drive.png';

const WorkflowSettings = ({
  workflowImage,
  setWorkflowImage,
  workflowName,
  setWorkflowName,
  workflowDescription,
  setWorkflowDescription,
  detailedDescription,
  setDetailedDescription,
  category,
  setCategory,
  visibility,
  setVisibility,
  emailNotifications,
  setEmailNotifications,
  hasDeadline,
  setHasDeadline,
  deadline,
  setDeadline,
  limitUsers,
  setLimitUsers,
  maxUsers,
  setMaxUsers,
  allowMultipleSubmissions,
  setAllowMultipleSubmissions,
  submissionLimit,
  setSubmissionLimit,
  showOriginalNavbar,  
}) => {
  // Active Sub-Tab
  const [activeSubTab, setActiveSubTab] = useState('general');
  const sidebarClass = showOriginalNavbar ? styles.sidebarLarge : styles.sidebarCompact;

    // We keep a ref to the hidden <input type="file" />
    const fileInputRef = useRef(null);

    // Handle drag states for the upload box
    const [isDragging, setIsDragging] = useState(false);
  
    // We define an event handler to open file dialog
    const handleUploadBoxClick = () => {
      // Programmatically trigger the hidden file input
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    };
  
    // Drag-and-drop handlers
    const handleDragOver = (e) => {
      e.preventDefault(); // Needed so drop event will fire
      setIsDragging(true);
    };
  
    const handleDragLeave = () => {
      setIsDragging(false);
    };
  
    const handleDrop = (e) => {
      e.preventDefault();
      setIsDragging(false);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        const file = e.dataTransfer.files[0];
        readFileAsBase64(file);
      }
    };
  
    // Common routine to read file as base64
    const readFileAsBase64 = (file) => {
      const reader = new FileReader();
      reader.onload = function (evt) {
        setWorkflowImage(evt.target.result);
      };
      reader.readAsDataURL(file);
    };
  
    // Handler for <input type="file" />
    const handleFileChange = (e) => {
      if (e.target.files && e.target.files[0]) {
        readFileAsBase64(e.target.files[0]);
      }
    };


  // SUB-TAB RENDERING
  const renderContent = () => {
    switch (activeSubTab) {
      case 'general':
      default:
        return (
          <div className={styles.contentContainer}>
            <h2 className={styles.sectionTitle}>General Settings</h2>

            {/* Workflow Name */}
            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>Workflow Name</label>
              <input
          type="text"
          className={styles.inputField}
          placeholder="Enter workflow name"
          value={workflowName}
          onChange={(e) => setWorkflowName(e.target.value)}
              />
              <small className={styles.fieldHint}>
          This will appear as the main title for your workflow.
              </small>
            </div>

            {/* Short Description */}
            <div className={`${styles.inputGroup} ${styles.positionRelative}`}>
              <label className={styles.inputLabel}>
          Short Description <span className={styles.charLimit}>(max 100 characters)</span>
              </label>
              <textarea
          className={`${styles.textArea} ${styles.shortDescTextarea}`}
          placeholder="Enter a brief description (max 100 characters)"
          maxLength={100}
          value={workflowDescription}
          onChange={(e) => setWorkflowDescription(e.target.value)}
              />
              {/* Character counter inside the box */}
              <div className={styles.charCountInside}>
          {workflowDescription.length}/100
              </div>
              <small className={styles.fieldHint}>
          This text appears on the workflow card. Keep it concise and catchy.
              </small>
            </div>

            {/* Detailed Description */}
            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>Detailed Description</label>
              <ReactQuill
          theme="snow"
          value={detailedDescription}
          onChange={setDetailedDescription}
          className={styles.richTextEditor}
          placeholder="Enter a detailed description of your workflow"
              />
              <small className={styles.fieldHint}>
          This is shown after a user enters the workflow but before they go into the tasks.
              </small>
            </div>

            {/* Category Field (NEW) */}
            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>Category</label>
              <select
          className={styles.inputField}
          value={category}
          onChange={(e) => setCategory(e.target.value)}
              >
          <option value="General">General</option>
          <option value="Finance">Finance</option>
          <option value="HR">HR</option>
          <option value="Marketing">Marketing</option>
          <option value="Operations">Operations</option>
          <option value="Sales">Sales</option>
          <option value="Tech">Tech</option>
          <option value="Other">Government Services</option>
          <option value="Other">Healthcare</option>
          <option value="Other">Education</option>
          <option value="Other">Civil Services</option>
          <option value="Other">Academic</option>
          <option value="Other">Non-Profit</option>
              </select>
              <small className={styles.fieldHint}>
          Categorize your workflow to help users find it easily.
              </small>
            </div>

          {/* Workflow Image => DRAG & DROP + CLICK */}
          <div className={styles.inputGroup}>
            <label className={styles.inputLabel}>Workflow Image</label>

          <div
              className={`${styles.imageUploadBox} ${isDragging ? styles.dragActive : ''}`}
              onClick={handleUploadBoxClick}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
          >
          {/* HIDDEN FILE INPUT */}
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            className={styles.hiddenFileInput}
            onChange={handleFileChange}
          />
          {workflowImage ? (
            <img
              src={workflowImage}
              alt="Workflow"
              className={styles.uploadedImage}
            />
          ) : (
            <div className={styles.uploadPlaceholder}>
              <i className="fa fa-upload" />
              <p>
                Drag and drop an image here, or{' '}
                <span style={{ textDecoration: 'underline', color: '#6d00cc' }}>Click here to upload</span>
              </p>
              <p className={styles.formatInfo}>PNG, JPG or GIF (MAX. 800x400px)</p>
            </div>
          )}
              </div>
              <small className={styles.fieldHint}>
          A visual representation of your workflow. It will be displayed on the workflow card.
              </small>
            </div>
          </div>
        );

      case 'appearance':
        return (
          <div className={styles.contentContainer}>
            <h2>Appearance</h2>
            <p>Customize the color scheme, icons, or branding of your workflow.</p>
          </div>
        );

      case 'notifications':
        return (
          <div className={styles.contentContainer}>
            <h2>Notifications</h2>
            <p>Manage how and when notifications are sent for this workflow.</p>
      
            {/* Email Notification toggle */}
            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>Email Notifications</label>
              <div className={styles.toggleContainer}>
                <label className={styles.switch}>
                  <input
                    type="checkbox"
                    checked={emailNotifications}
                    onChange={() => setEmailNotifications(!emailNotifications)}
                  />
                  <span className={styles.slider}></span>
                </label>
                <span className={styles.toggleLabel}>
                  {emailNotifications ? 'Enabled' : 'Disabled'}
                </span>
              </div>
              <small className={styles.fieldHint}>
                Notify when a user submits tasks or completes the entire workflow.
              </small>
            </div>
          </div>
        );
      
      case 'permissions':
        return (
          <div className={styles.contentContainer}>
            <h2>Permissions</h2>
            <p>Control who can view or edit this workflow.</p>

            <div className={styles.visibilityChoice}>
              <div
                className={`${styles.visibilityOption} ${
                  visibility === 'private' ? styles.selected : ''
                }`}
                onClick={() => setVisibility('private')}
              >
                <div className={styles.optionHeader}>
                  <i className="fa fa-lock" />
                  <span>Private</span>
                </div>
                <small>Not visible to anyone except you.</small>
              </div>

              <div
                className={`${styles.visibilityOption} ${
                  visibility === 'restricted' ? styles.selected : ''
                }`}
                onClick={() => setVisibility('restricted')}
              >
                <div className={styles.optionHeader}>
                  <i className="fa fa-user-secret" />
                  <span>Restricted</span>
                </div>
                <small>Only those with the direct URL can access.</small>
              </div>

              <div
                className={`${styles.visibilityOption} ${
                  visibility === 'public' ? styles.selected : ''
                }`}
                onClick={() => setVisibility('public')}
              >
                <div className={styles.optionHeader}>
                  <i className="fa fa-unlock-alt" />
                  <span>Public</span>
                </div>
                <small>Visible to everyone in WorkflowMaster Community.</small>
              </div>
            </div>
          </div>
        );

      case 'integrations':
        return (
          <div className={styles.contentContainer}>
            <h2>Integrations</h2>
            <p>Connect external apps or services to your workflow.</p>

            <div className={styles.integrationOption}>
              <div className={styles.integrationImageWrapper}>
                <img
                  src={GoogleSheetsLogo}
                  alt="Google Sheets"
                  className={styles.integrationImage}
                />
              </div>
              <div className={styles.integrationText}>
                <h4>Google Sheets (Comming Soon...)</h4>
                <p>Save form data and tasks as rows in Google Sheets.</p>
                <button disabled className={styles.connectButton}>Comming Soon...</button>
              </div>
            </div>

            <div className={styles.integrationOption}>
              <div className={styles.integrationImageWrapper}>
                <img
                  src={GoogleDriveLogo}
                  alt="Google Drive"
                  className={styles.integrationImage}
                />
              </div>
              <div className={styles.integrationText}>
                <h4>Google Drive (Comming Soon...)</h4>
                <p>Store uploaded files directly in your Google Drive folder.</p>
                <button disabled className={styles.connectButton}>Comming Soon...</button>
              </div>
            </div>
          </div>
        );

      case 'advanced':
        return (
          <div className={styles.contentContainer}>
            <h2>Advanced Settings</h2>
            <p>Additional controls for deadlines, submission limits, or user caps.</p>
      
            {/* MULTIPLE SUBMISSIONS (already shown in the previous example) */}
            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>Allow Multiple Submissions</label>
              <div className={styles.toggleContainer}>
                <label className={styles.switch}>
                  <input
                    type="checkbox"
                    checked={allowMultipleSubmissions}
                    onChange={() => setAllowMultipleSubmissions(!allowMultipleSubmissions)}
                  />
                  <span className={styles.slider}></span>
                </label>
                <span className={styles.toggleLabel}>
                  {allowMultipleSubmissions ? 'Enabled' : 'Disabled'}
                </span>
              </div>
              <small className={styles.fieldHint}>
                Let users submit this workflow multiple times. Great for repeated entries or cyclical tasks.
              </small>
            </div>
      
            {allowMultipleSubmissions && (
              <div className={styles.inputGroup}>
                <label className={styles.inputLabel}>Submission Limit per User</label>
                <input
                  type="number"
                  className={styles.inputField}
                  min="0"
                  value={submissionLimit}
                  onChange={(e) => setSubmissionLimit(e.target.value)}
                />
                <small className={styles.fieldHint}>
                  0 means unlimited. Otherwise, users can only submit it up to this number of times.
                </small>
              </div>
            )}
      
            {/* DEADLINE TOGGLE */}
            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>Has Deadline</label>
              <div className={styles.toggleContainer}>
                <label className={styles.switch}>
                  <input
                    type="checkbox"
                    checked={hasDeadline}
                    onChange={() => setHasDeadline(!hasDeadline)}
                  />
                  <span className={styles.slider}></span>
                </label>
                <span className={styles.toggleLabel}>
                  {hasDeadline ? 'Yes' : 'No'}
                </span>
              </div>
              <small className={styles.fieldHint}>
                If enabled, users must submit before this date/time.
              </small>
            </div>
      
            {hasDeadline && (
              <div className={styles.inputGroup}>
                <label className={styles.inputLabel}>Deadline Date/Time</label>
                <input
                  type="datetime-local"
                  className={styles.inputField}
                  value={deadline}
                  onChange={(e) => setDeadline(e.target.value)}
                />
                <small className={styles.fieldHint}>
                  After this deadline, the workflow can no longer be started or submitted.
                </small>
              </div>
            )}
      
            {/* MAX USERS TOGGLE */}
            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>Limit Total Number of Users</label>
              <div className={styles.toggleContainer}>
                <label className={styles.switch}>
                  <input
                    type="checkbox"
                    checked={limitUsers}
                    onChange={() => setLimitUsers(!limitUsers)}
                  />
                  <span className={styles.slider}></span>
                </label>
                <span className={styles.toggleLabel}>
                  {limitUsers ? 'Yes' : 'No'}
                </span>
              </div>
              <small className={styles.fieldHint}>
                If enabled, only a certain number of users can start this workflow.
              </small>
            </div>
      
            {limitUsers && (
              <div className={styles.inputGroup}>
                <label className={styles.inputLabel}>Maximum Users</label>
                <input
                  type="number"
                  className={styles.inputField}
                  min="1"
                  value={maxUsers}
                  onChange={(e) => setMaxUsers(e.target.value)}
                />
                <small className={styles.fieldHint}>
                  Once this limit is reached, no new user can start the workflow.
                </small>
              </div>
            )}
          </div>
        );
  }
  };

  return (
    <div className={styles.settingsContainer}>
      {/* SIDEBAR: add dynamic class */}
      <div className={`${styles.sidebar} ${sidebarClass}`}>
        <ul className={styles.navList}>
          <li
            className={`${styles.navItem} ${activeSubTab === 'general' ? styles.active : ''}`}
            onClick={() => setActiveSubTab('general')}
          >
            <i className="fa fa-globe" style={{ fontSize: '20px' }} />
            General
          </li>
          <li
            className={`${styles.navItem} ${activeSubTab === 'appearance' ? styles.active : ''}`}
            onClick={() => setActiveSubTab('appearance')}
          >
            <i className="fa fa-paint-brush" style={{ fontSize: '20px' }} />
            Appearance
          </li>
          <li
            className={`${styles.navItem} ${activeSubTab === 'notifications' ? styles.active : ''}`}
            onClick={() => setActiveSubTab('notifications')}
          >
            <i className="fa fa-bell" style={{ fontSize: '20px' }} />
            Notifications
          </li>
          <li
            className={`${styles.navItem} ${activeSubTab === 'permissions' ? styles.active : ''}`}
            onClick={() => setActiveSubTab('permissions')}
          >
            <i className="fa fa-lock" style={{ fontSize: '20px' }} />
            Permissions
          </li>
          <li
            className={`${styles.navItem} ${activeSubTab === 'integrations' ? styles.active : ''}`}
            onClick={() => setActiveSubTab('integrations')}
          >
            <i className="fa fa-plug" style={{ fontSize: '20px' }} />
            Integrations
          </li>
          <li
            className={`${styles.navItem} ${activeSubTab === 'advanced' ? styles.active : ''}`}
            onClick={() => setActiveSubTab('advanced')}
          >
            <i className="fa fa-cogs" style={{ fontSize: '20px' }} />
            Advanced
          </li>
        </ul>
      </div>

      {/* MAIN CONTENT (FIXED WIDTH) */}
      <div className={styles.mainPanel}>
        <div className={styles.fixedWidthContainer}>
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default WorkflowSettings;
