// src/components/WorkflowBuilder/TaskNode.jsx
import React from "react";
import { Handle, Position } from "reactflow";
import styles from "../../styles/components/WorkflowBuilder/TaskNode.module.css";

function TaskNode({ data }) {
  return (
    <div className={styles.taskBox} style={{ backgroundColor: data.color }}>
      <div className={styles.taskTitle}>{data.label}</div>

      {/* Left handle -> target */}
      <Handle
        type="target"
        position={Position.Left}
        style={{ top: "50%", transform: "translateY(-50%)" }}
      />

      {/* Right handle -> Source */}
      <Handle
        type="source"
        position={Position.Right}
        style={{ top: "50%", transform: "translateY(-50%)" }}
      />
    </div>
  );
}

export default TaskNode;
