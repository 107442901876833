import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiSearch, FiX, FiUser, FiClock, FiEdit, FiSend, FiCheck, FiXCircle, FiPauseCircle, FiPlayCircle } from 'react-icons/fi';
import { FaCheckCircle, FaArrowRight } from 'react-icons/fa';
import styles from '../../styles/components/WorkflowDetails/WorkflowTasks.module.css';
import ProgressBar from './ProgressBar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

const WorkflowTasks = ({ userTasks, workflowId, userTasksMap }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');

    const filteredTasks = userTasks.filter(task =>
        task.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleStepClick = (index) => {
        // Safety check in case index is out of bounds
        if (index < 0 || index >= userTasks.length) return;
        const restriction_type = userTasksMap[userTasks[index].task]?.restriction_type;
        console.log('restriction_type:', restriction_type);
        // get the restriction type of the clicked task
        if (restriction_type === 'must_submit_before_view') {
            // get the names of the previous tasks that are not completed
            const previousTasks = userTasks.slice(0, index).filter(task => !userTasksMap[task.task]?.completed);
            if (previousTasks.length > 0) {
                const taskNames = previousTasks.map(task => task.name).join(', ');
                toast.warning(`You must complete the following tasks before viewing this one: ${taskNames}`);
                return;
            }
        }
        const clickedTask = filteredTasks[index];
        const taskId = clickedTask.task;
        const url = `/user-dashboard/workflows/${workflowId}/step/${taskId}`;
        window.open(url, '_blank');
    };

    const isOverdue = (dueDateStr) => {
        if (!dueDateStr) return false;
        const dueDate = new Date(dueDateStr);
        const now = new Date();
        return dueDate < now;
    };

    // Returns a small colored dot and appropriate icon based on status.
    const getStatusIndicator = (status) => {
        switch (status) {
            case 'Draft':
                return {
                    colorClass: styles.dotGrey,
                    icon: <FiEdit />,
                    label: t('workflowDetail.statuses.Draft'),
                };
            case 'Submitted':
                return {
                    colorClass: styles.dotBlue,
                    icon: <FiSend />,
                    label: t('workflowDetail.statuses.Submitted'),
                };
            case 'Approved':
                return {
                    colorClass: styles.dotGreen,
                    icon: <FiCheck />,
                    label: t('workflowDetail.statuses.Approved'),
                };
            case 'Rejected':
                return {
                    colorClass: styles.dotRed,
                    icon: <FiXCircle />,
                    label: t('workflowDetail.statuses.Rejected'),
                };
            case 'Not Started':
                return {
                    colorClass: styles.dotGrey,
                    icon: <FiPauseCircle />,
                    label: t('workflowDetail.statuses.Not Started'),
                };
            case 'In Progress':
                return {
                    colorClass: styles.dotBlue,
                    icon: <FiPlayCircle />,
                    label: t('workflowDetail.statuses.In Progress'),
                };
            case 'Completed':
                return {
                    colorClass: styles.dotGreen,
                    icon: <FaCheckCircle style={{ color: 'var(--success-color)' }} />,
                    label: t('workflowDetail.statuses.Completed'),
                };
            default:
                return {
                    colorClass: styles.dotGrey,
                    icon: null,
                    label: t('workflowDetail.statuses.Pending'),
                };
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <h2 className={styles.title}>{t('workflowDetail.workflowTasks')}</h2>
                <p className={styles.subtitle}>{t('workflowDetail.manageYourWorkflow')}</p>
            </div>
            <div className={styles.tasksContainer}>
                <div className={styles.searchBarContainer}>
                    <div className={styles.searchWrapper}>
                        <FiSearch className={styles.searchIcon} />
                        <input
                            type="text"
                            className={styles.searchInput}
                            placeholder={t('workflowDetail.searchTasksPlaceholder')}
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            aria-label="Search tasks by name"
                        />
                        {searchQuery && (
                            <button
                                className={styles.clearFilterButton}
                                onClick={() => setSearchQuery('')}
                                aria-label="Clear search"
                            >
                                <FiX />
                            </button>
                        )}
                    </div>
                </div>

                {filteredTasks.map((task, index) => {
                    const isDone = task.num_filled_fields === task.num_fields;
                    const progressValue = (task.num_filled_fields / task.num_fields) * 100 || 0;
                    const overdue = isOverdue(task.due_date);
                    const status = task.status || 'Draft';
                    const { colorClass, icon, label } = getStatusIndicator(status);

                    return (
                        <div
                            className={`${styles.taskItem} ${isDone ? styles.taskDone : ''}`}
                            key={task.id}
                            onClick={() => handleStepClick(index)}
                            title={`Click to view details of "${task.name}"`}
                            tabIndex="0"
                            role="button"
                            aria-label={`View details of ${task.name}`}
                        >
                            <div className={styles.leftBorder}></div>
                            <div className={styles.taskContent}>
                                <div className={styles.taskHeader}>
                                    <div className={styles.taskHeaderLeft}>
                                        <span className={styles.stepNumber} aria-label={isDone ? 'Task Done' : 'Task In Progress'}>
                                            {isDone ? <FaCheckCircle style={{ color: 'var(--success-color)' }} /> : <FaArrowRight />} Step {index + 1}
                                        </span>
                                        <span className={styles.taskName}>{task.name}</span>
                                    </div>
                                    <div className={styles.taskHeaderRight}>
                                        {status && (
                                            <span className={`${styles.taskStatus} ${styles.taskStatusPill}`}>
                                                <span className={`${styles.statusDot} ${colorClass}`}></span>
                                                {icon}
                                                <span className={styles.statusLabel}>{label}</span>
                                            </span>
                                        )}
                                    </div>
                                </div>
                                {/*<div className={styles.taskMeta}>
                                    {task.assignee && (
                                        <span className={styles.assigneeContainer}>
                                            <FiUser className={styles.assigneeIcon} />
                                            <span className={styles.assigneeName}>{task.assignee.name}</span>
                                        </span>
                                    )}
                                    {task.due_date && (
                                        <span
                                            className={styles.dueDate}
                                            style={{ color: overdue ? 'var(--error-color)' : '#444' }}
                                        >
                                            <FiClock /> Due: {new Date(task.due_date).toLocaleDateString()}
                                        </span>
                                    )}
                                </div>*/}
                                <div className={styles.progressArea}>
                                    <ProgressBar progressValue={Math.round(progressValue)} />
                                </div>
                            </div>
                        </div>
                    );
                })}

                {filteredTasks.length === 0 && (
                    <div className={styles.noResults}>
                        <p>{t('workflowDetail.noTasksMatchSearch')}</p>
                        <button
                            onClick={() => setSearchQuery('')}
                            className={styles.resetButton}
                        >
                            {t('workflowDetail.resetFilters')}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default WorkflowTasks;
