import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../styles/components/WorkflowDetails/UserWorkflowCard.module.css';
import { useTranslation } from 'react-i18next';

function UserWorkflowCard({ workflow, onDelete }) {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  if (!workflow) return null;

  const workflowInfo = workflow?.workflow || {};
  const progress = workflow.num_completed_tasks
    ? Math.round((workflow.num_completed_tasks / workflow.num_tasks) * 100)
    : 0;

  const status = (workflow.status || t('userWorkflowCard.unknown')).toLowerCase();

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(workflow.id);
  };

  return (
    <div className={`${styles.card} ${isArabic ? styles.rtl : ''}`}>
      <Link to={`/user-dashboard/workflows/${workflow.id}`} className={styles.cardLink}>
        {/* Image section */}
        <div className={styles.cardImageWrapper}>
          <img
            src={
              workflowInfo.image_url ||
              `https://via.placeholder.com/400x200.png?text=${encodeURIComponent(
                t('userWorkflowCard.noImage')
              )}`
            }
            alt={workflowInfo.name || t('userWorkflowCard.untitledWorkflow')}
            className={styles.cardImage}
          />
        </div>

        {/* Main content */}
        <div className={styles.cardBody}>
          <h2 className={styles.cardTitle}>
            {workflowInfo.name || t('userWorkflowCard.untitledWorkflow')}
          </h2>

          <span className={styles.categoryPill}>
            {workflowInfo.category || t('userWorkflowCard.uncategorized')}
          </span>

          <p className={styles.cardDescription}>
            {workflowInfo.description || t('userWorkflowCard.noDescription')}
          </p>

          <span className={`${styles.statusPill} ${styles.statusActive}`}>
            {workflow.status || t('userWorkflowCard.unknown')}
          </span>
        </div>
      </Link>

      {/* Footer: Delete + Progress */}
      <div className={styles.cardFooter}>
        <button className={styles.deleteButton} onClick={handleDelete}>
          <i className="fas fa-trash-alt"></i> {t('userWorkflowCard.deleteBtn')}
        </button>

        <div className={styles.progressWrapper}>
          <svg className={styles.creativeProgressCircle} viewBox="0 0 100 100">
            <defs>
              <linearGradient id="ProgressGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stopColor="#6a1b9a" />
                <stop offset="100%" stopColor="#ff4081" />
              </linearGradient>
            </defs>
            {/* Background circle */}
            <circle className={styles.creativeCircleBg} cx="50" cy="50" r="45" />
            {/* Dynamic fill circle */}
            <circle
              className={styles.creativeCircleFill}
              cx="50"
              cy="50"
              r="45"
              strokeDasharray="283"
              strokeDashoffset={283 - (283 * progress) / 100}
            />
          </svg>
          <span className={styles.creativeProgressText}>{progress}%</span>
        </div>
      </div>
    </div>
  );
}

export default UserWorkflowCard;
