import React from 'react';
import { FaUsers } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import Styles from '../../styles/components/WorkflowDetails/BrowseWorkflowCard.module.css';

// 1) Import the translation hook
import { useTranslation } from 'react-i18next';

function BrowseWorkflowCard({ workflow, isAdding, onAdd }) {
  const { t } = useTranslation(); // 2) get the translation function

  const imageUrl = workflow.image_url ||
    `https://via.placeholder.com/300x200.png?text=${encodeURIComponent(t('browseWorkflowCard.noImage'))}`;
  const description = workflow.description || t('browseWorkflowCard.noDescription');

  return (
    <div className={Styles.browseCard}>
      <div className={Styles.browseCardHeader}>
        <img
          src={imageUrl}
          alt={workflow.name}
          className={Styles.browseCardImage}
        />
      </div>

      <div className={Styles.browseCardBody}>
        <h2 className={Styles.browseCardTitle}>{workflow.name}</h2>

        <div className={Styles.browseCardUserInfo}>
          <FaUsers size={14} className={Styles.userIcon} />
          <span className={Styles.userCountText}>{workflow.user_count || 0}</span>
        </div>

        <p className={Styles.browseCardCategory}>{workflow.category}</p>
        <p className={Styles.browseCardDescription}>{description}</p>
      </div>

      <div className={Styles.browseCardFooter}>
        <Link to={`/browse-workflows/workflows/${workflow.id}`} className={Styles.viewButton}>
          <i className="fas fa-eye"></i> {t('browseWorkflowCard.viewBtn')}
        </Link>

        <button className={Styles.addButton} onClick={onAdd} disabled={isAdding}>
          {isAdding ? (
            <>
              <ClipLoader size={9} color="#ffffff" />
              {` ${t('browseWorkflowCard.addingEllipsis')}`}
            </>
          ) : (
            <>
              <i className="fas fa-plus"></i> {t('browseWorkflowCard.addBtn')}
            </>
          )}
        </button>
      </div>
    </div>
  );
}

export default BrowseWorkflowCard;
