import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './AuthPage.module.css';
import {
    initializeGoogleSignIn,
    handleCredentialResponse,
} from '../services/googleAuthService';
import config from '../config';
import { UserContext } from './UserContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ClipLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';

const AuthPage = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    const [isSignUp, setIsSignUp] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    });
    const [errors, setErrors] = useState({});
    const { setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [isResetPassword, setIsResetPassword] = useState(false);
    const [isResendActivation, setIsResendActivation] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const validatePassword = (password) => {
        const validationErrors = [];
        // references to t('errors.passwordTooShort'), etc.
        if (password.length < 9)
            validationErrors.push(t('errors.passwordTooShort'));
        if (!/[A-Z]/.test(password) || !/[a-z]/.test(password))
            validationErrors.push(t('errors.passwordCaseMix'));
        if (!/\d/.test(password))
            validationErrors.push(t('errors.passwordDigit'));
        if (!/[!@#$%^&*]/.test(password))
            validationErrors.push(t('errors.passwordSpecial'));

        return validationErrors;
    };

    const handleLoginErrors = (errorData) => {
        const newErrors = {};

        if (errorData.email_or_username) {
            newErrors.email = errorData.email_or_username;
        }
        if (errorData.password) {
            newErrors.password = errorData.password;
        }
        if (errorData.non_field_errors) {
            newErrors.email = errorData.non_field_errors;
        }

        setErrors(newErrors);
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        const newErrors = {};

        if (!formData.email) {
            newErrors.email = t('errors.requiredEmail');
        }
        if (!formData.password) {
            newErrors.password = [t('errors.requiredPassword')];
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setIsLoading(true);
        try {
            const response = await fetch(`${config.apiUrl}/auth/login/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email_or_username: formData.email,
                    password: formData.password,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('authToken', data.key);
                setUser({ username: data.username });
                setIsLoading(false);
                navigate('/user-dashboard');
            } else {
                const errorData = await response.json();
                handleLoginErrors(errorData);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Login error:', error);
            toast.error(t('auth.loginFail'));
            setIsLoading(false);
        }
    };

    const handleToggle = () => {
        if (isSignUp) {
            navigate('/login');
        } else {
            navigate('/register');
        }
        setErrors({});
        setFormData({ firstName: '', lastName: '', email: '', password: '' });
        setIsResetPassword(false);
        setIsResendActivation(false);
    };

    useEffect(() => {
        if (location.pathname === '/login') {
            setIsSignUp(false);
        } else if (location.pathname === '/register') {
            setIsSignUp(true);
        }
    }, [location]);

    // Initialize Google Sign-In
    useEffect(() => {
        const callback = async (response) => {
            const result = await handleCredentialResponse(
                response.credential,
                config.apiUrl
            );
            if (result.success) {
                localStorage.setItem('authToken', result.key);
                setUser({ username: result.username });
                navigate(result.created ? '/profile' : '/user-dashboard');
            } else {
                toast.error('Google Sign-In failed: ' + result.message);
            }
        };

        initializeGoogleSignIn(config.googleClientId, callback);
    }, [navigate, setUser]);

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const newFormData = { ...formData, [name]: value };
        setFormData(newFormData);

        const newErrors = { ...errors };
        // Validate First Name
        if (name === 'firstName') {
            if (!value.trim()) newErrors.firstName = t('errors.requiredFirstName');
            else delete newErrors.firstName;
        }
        // Validate Last Name
        if (name === 'lastName') {
            if (!value.trim()) newErrors.lastName = t('errors.requiredLastName');
            else delete newErrors.lastName;
        }
        // Validate Email
        if (name === 'email') {
            if (!value.trim()) newErrors.email = t('errors.requiredEmail');
            else if (!validateEmail(value)) newErrors.email = t('errors.invalidEmail');
            else delete newErrors.email;
        }
        // Validate Password
        if (name === 'password' && isSignUp) {
            const passwordErrors = validatePassword(value);
            if (passwordErrors.length > 0) {
                newErrors.password = passwordErrors;
            } else {
                delete newErrors.password;
            }
        }
        setErrors(newErrors);
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        const newErrors = {};

        if (!formData.firstName.trim()) {
            newErrors.firstName = t('errors.requiredFirstName');
        }
        if (!formData.lastName.trim()) {
            newErrors.lastName = t('errors.requiredLastName');
        }
        if (!formData.email.trim()) {
            newErrors.email = t('errors.requiredEmail');
        } else if (!validateEmail(formData.email)) {
            newErrors.email = t('errors.invalidEmail');
        }
        const passwordErrors = validatePassword(formData.password);
        if (passwordErrors.length > 0) {
            newErrors.password = passwordErrors;
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setIsLoading(true);
        try {
            const response = await fetch(`${config.apiUrl}/auth/register/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    username: formData.firstName + formData.lastName,
                    email: formData.email,
                    password1: formData.password,
                    password2: formData.password,
                    first_name: formData.firstName,
                    last_name: formData.lastName,
                }),
            });

            if (!response.ok) throw new Error('Registration failed');

            toast.success(t('auth.registrationSuccess'));
            setIsLoading(false);
            navigate('/login');
        } catch (error) {
            console.error('Registration error:', error);
            toast.error(t('auth.registrationFail'));
            setIsLoading(false);
        }
    };

    const handleResetPassword = async (event) => {
        event.preventDefault();
        const newErrors = {};
        if (!formData.email) {
            newErrors.email = t('errors.requiredEmail');
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        try {
            const response = await fetch(`${config.apiUrl}/auth/password-reset/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: formData.email }),
            });
            if (response.ok) {
                toast.success(
                    'If an account with that email exists, we have sent a password reset link.'
                );
                setIsResetPassword(false);
                setFormData({ ...formData, email: '' });
                newErrors.email = '';
                setErrors(newErrors);
            } else if (response.status === 404) {
                newErrors.email = t('auth.noAccountFound');
                setErrors(newErrors);
            } else {
                toast.error(t('auth.loginFail'));
            }
        } catch (error) {
            console.error('Reset password error:', error);
            toast.error(t('auth.loginFail'));
        }
    };

    const handleResendActivationEmail = async (event) => {
        event.preventDefault();
        const newErrors = {};
        if (!formData.email) {
            newErrors.email = t('errors.requiredEmail');
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        try {
            const response = await fetch(`${config.apiUrl}/auth/resend-activation/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: formData.email }),
            });

            if (response.ok) {
                toast.success(t('auth.activationResent'));
                setIsResendActivation(false);
                setFormData({ ...formData, email: '' });
                newErrors.email = '';
                setErrors(newErrors);
            } else if (response.status === 404) {
                newErrors.email = t('auth.noAccountFound');
                setErrors(newErrors);
            } else {
                toast.error(t('auth.loginFail'));
            }
        } catch (error) {
            console.error('Resend activation email error:', error);
            toast.error(t('auth.loginFail'));
        }
    };

    const showResetPasswordForm = (e) => {
        e.preventDefault();
        setIsResetPassword(true);
        setErrors({});
        setFormData({ ...formData, password: '' });
        setFormData({ ...formData, email: '' });
    };

    const showResendActivationForm = (e) => {
        e.preventDefault();
        setIsResendActivation(true);
        setIsResetPassword(false);
        setErrors({});
        setFormData({ ...formData, password: '' });
        setFormData({ ...formData, email: '' });
    };

    const backToSignIn = (e) => {
        e.preventDefault();
        setIsResetPassword(false);
        setIsResendActivation(false);
        setErrors({});
    };

    useEffect(() => {
        document.body.classList.add(styles.authPageBody);
        return () => {
            document.body.classList.remove(styles.authPageBody);
        };
    }, []);

    const signUpBlock = (
        <div className={`${styles.formContainer} ${styles.signUp}`}>
            <form onSubmit={handleRegister}>
                <h1>{t('auth.createAccount')}</h1>
                <div id="googleSignInButton" className={styles.googleSignIn}>
                    {/* {t('auth.loginGoogle')} - could be a Google button label */}
                </div>

                <div className={styles.nameInputs}>
                    <div className={styles.inputContainer}>
                        {errors.firstName && (
                            <p className={styles.error}>{errors.firstName}</p>
                        )}
                        <input
                            type="text"
                            name="firstName"
                            placeholder={t('auth.firstName')}
                            value={formData.firstName}
                            onChange={handleChange}
                            className={errors.firstName ? styles.errorBorder : ''}
                        />
                    </div>
                    <div className={styles.inputContainer}>
                        {errors.lastName && <p className={styles.error}>{errors.lastName}</p>}
                        <input
                            type="text"
                            name="lastName"
                            placeholder={t('auth.lastName')}
                            value={formData.lastName}
                            onChange={handleChange}
                            className={errors.lastName ? styles.errorBorder : ''}
                        />
                    </div>
                </div>

                {errors.email && <p className={styles.error}>{errors.email}</p>}
                <input
                    type="email"
                    name="email"
                    placeholder={t('auth.email')}
                    value={formData.email}
                    onChange={handleChange}
                    className={errors.email ? styles.errorBorder : ''}
                />

                <div className={styles.passwordContainer}>
                    <div className={styles.passwordField}>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            placeholder={t('auth.password')}
                            value={formData.password}
                            onChange={handleChange}
                            className={errors.password ? styles.errorBorder : ''}
                        />
                        <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                            onClick={togglePasswordVisibility}
                            className={styles.passwordToggleIcon}
                        />
                    </div>
                    {errors.password && (
                        <div className={styles.errorList}>
                            {Array.isArray(errors.password)
                                ? errors.password.map((err, index) => (
                                    <span key={index} className={styles.error}>
                                        {err}
                                    </span>
                                ))
                                : null}
                        </div>
                    )}
                </div>

                <button type="submit" disabled={isLoading} className={styles.button}>
                    {isLoading ? (
                        <>
                            <ClipLoader size={15} color="#ffffff" /> {` ${t('auth.signUp')}...`}
                        </>
                    ) : (
                        t('auth.signUp')
                    )}
                </button>
                <p className={styles.terms}>
                    {t('auth.bySigningUp')}{' '}
                    <br />
                    <a href="#">{t('auth.termsLink')}</a>.
                </p>
            </form>
        </div>

    );

    const signInBlock = (
        <div className={`${styles.formContainer} ${styles.signIn}`}>
            {!isResetPassword && !isResendActivation ? (
                <form onSubmit={handleLogin}>
                    <h1>{t('auth.signInTitle')}</h1>
                    <div id="googleSignInButton2" className={styles.googleSignIn}>
                        {/* {t('auth.loginGoogle')} */}
                    </div>

                    {errors.email && <p className={styles.error}>{errors.email}</p>}
                    <input
                        type="text"
                        name="email"
                        placeholder={`${t('auth.email')} or Username`}
                        value={formData.email}
                        onChange={handleChange}
                        className={errors.email ? styles.errorBorder : ''}
                    />

                    {errors.password && <p className={styles.error}>{errors.password}</p>}
                    <div className={styles.passwordField}>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            placeholder={t('auth.password')}
                            value={formData.password}
                            onChange={handleChange}
                            className={errors.password ? styles.errorBorder : ''}
                        />
                        <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                            onClick={togglePasswordVisibility}
                            className={styles.passwordToggleIcon}
                        />
                    </div>
                    <br />
                    <a href="#" className={styles.link} onClick={showResetPasswordForm}>
                        {t('auth.forgetPassword')}
                    </a>
                    <br />
                    <button type="submit" disabled={isLoading} className={styles.button}>
                        {isLoading ? (
                            <>
                                <ClipLoader size={15} color="#ffffff" />{' '}
                                {` ${t('auth.signIn')}...`}
                            </>
                        ) : (
                            t('auth.signIn')
                        )}
                    </button>
                    <p>
                        {t('auth.dontHaveAccount')}{' '}
                        <a href="#" className={styles.link} onClick={handleToggle}>
                            {t('auth.signUp')}
                        </a>
                    </p>
                    <a href="#" className={styles.link} onClick={showResendActivationForm}>
                        {t('auth.resendActivation')}
                    </a>
                </form>
            ) : isResetPassword ? (
                // ================= RESET PASSWORD =================
                <form onSubmit={handleResetPassword}>
                    <h1>{t('auth.resetPasswordTitle')}</h1>
                    <p className={styles.resetText}>{t('auth.resetPasswordDesc')}</p>
                    {errors.email && <p className={styles.error}>{errors.email}</p>}
                    <input
                        type="email"
                        name="email"
                        placeholder={t('auth.email')}
                        value={formData.email}
                        onChange={handleChange}
                        className={errors.email ? styles.errorBorder : ''}
                    />
                    <button type="submit" className={styles.button}>
                        {t('auth.resetPasswordBtn')}
                    </button>
                    <p>
                        <a href="#" className={styles.link} onClick={backToSignIn}>
                            {t('auth.signInTitle')}
                        </a>
                    </p>
                </form>
            ) : (
                // ================= RESEND ACTIVATION =================
                <form onSubmit={handleResendActivationEmail}>
                    <h1>{t('auth.verifyAccountTitle')}</h1>
                    <p className={styles.resendText}>{t('auth.verifyAccountDesc')}</p>
                    {errors.email && <p className={styles.error}>{errors.email}</p>}
                    <input
                        type="email"
                        name="email"
                        placeholder={t('auth.email')}
                        value={formData.email}
                        onChange={handleChange}
                        className={errors.email ? styles.errorBorder : ''}
                    />
                    <button type="submit" className={styles.button}>
                        {t('auth.resendActivation')}
                    </button>
                    <p>
                        <a href="#" className={styles.link} onClick={backToSignIn}>
                            {t('auth.signInTitle')}
                        </a>
                    </p>
                </form>
            )}
        </div>
    );

    return (
        <div className={`${styles.container} ${isSignUp ? styles.active : ''} ${isArabic ? styles.rtl : ''}`}>
            {isArabic ? (
                <>
                    {signInBlock}
                    {signUpBlock}
                </>
            ) : (
                <>
                    {signUpBlock}
                    {signInBlock}
                </>
            )}

            {/* ========== TOGGLE CONTAINER ========== */}
            <div className={styles.toggleContainer}>
                <div className={styles.toggle}>
                    {/* 
            If Arabic, we show "Welcome Back!" panel on the right. 
            So we render toggleRight first, then toggleLeft. 
            If not Arabic, we do left then right.
          */}
                    {isArabic ? (
                        <>
                            <div className={styles.togglePanel + ' ' + styles.toggleRight}>
                                <h1>{t('auth.welcomeBackTitle')}</h1>
                                <p>{t('auth.welcomeBackDesc')}</p>
                                <button onClick={handleToggle} className={styles.button}>
                                    {t('auth.signInBtn')}
                                </button>
                            </div>

                            <div className={styles.togglePanel + ' ' + styles.toggleLeft}>
                                <h1>{t('auth.helloFriendTitle')}</h1>
                                <p>{t('auth.helloFriendDesc')}</p>
                                <button onClick={handleToggle} className={styles.button}>
                                    {t('auth.signUpBtn')}
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={styles.togglePanel + ' ' + styles.toggleLeft}>
                                <h1>{t('auth.welcomeBackTitle')}</h1>
                                <p>{t('auth.welcomeBackDesc')}</p>
                                <button onClick={handleToggle} className={styles.button}>
                                    {t('auth.signInBtn')}
                                </button>
                            </div>

                            <div className={styles.togglePanel + ' ' + styles.toggleRight}>
                                <h1>{t('auth.helloFriendTitle')}</h1>
                                <p>{t('auth.helloFriendDesc')}</p>
                                <button onClick={handleToggle} className={styles.button}>
                                    {t('auth.signUpBtn')}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AuthPage;
