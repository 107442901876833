import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import parse from 'html-react-parser';
import config from '../../config';
import styles from '../../styles/pages/UserDashboard/WorkflowDetail.module.css';

import WorkflowTasks from '../../components/WorkflowDetails/WorkflowTasks';
import WorkflowVisualization from '../../components/WorkflowDetails/WorkflowVisualization';

import { useTranslation } from 'react-i18next';

function WorkflowDetail() {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const { id } = useParams();
    const [workflow, setWorkflow] = useState(null);

    useEffect(() => {
        const fetchWorkflow = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                const response = await axios.get(`${config.apiUrl}/user-workflows/${id}/`, {
                    headers: {
                        Authorization: `Token ${authToken}`,
                    },
                });
                setWorkflow(response.data);
            } catch (error) {
                console.error('Failed to fetch workflow details:', error);
                navigate('/user-dashboard');
            }
        };

        fetchWorkflow();
    }, [id, navigate]);

    // 3) If no data yet, show localized loading
    if (!workflow) {
        return (
            <div className={styles.loadingContainer}>
                <div className={styles.loader}></div>
                <div className={styles.loadingText}>{t('workflowDetail.loading')}</div>
            </div>
        );
    }

    // 4) If we have data but no tasks
    if (!workflow.workflow || !workflow.user_tasks) {
        return <h2 className={styles.loadingText}>{t('workflowDetail.noTasks')}</h2>;
    }

    const userTasks = workflow.user_tasks;
    userTasks.sort((a, b) => a.task - b.task);

    // Build userTasksMap
    const userTasksMap = {};
    userTasks.forEach((ut) => {
        const isCompleted = !!ut.isCompleted;
        const restriction_type = ut.dependency_type || 'no_restriction';
        userTasksMap[ut.task] = {
            completed: isCompleted,
            restriction_type,
        };
    });

    // Create a Gantt-like representation
    const palette = [
        '#00c48f',
        '#39afd1',
        '#ffd966',
        '#ad75db',
        '#ff8c66',
        '#e95672',
        '#4f8cf5',
        '#f5b74f',
        '#999999',
    ];
    const coloredTasks = userTasks.map((t, i) => ({
        ...t,
        start: i * 2,
        length: 2,
        row: i,
        color: palette[i % palette.length],
    }));

    // Build dependencies
    const dependencies = [];
    coloredTasks.forEach((task) => {
        if (task.opening_tasks && task.opening_tasks.length > 0) {
            task.opening_tasks.forEach((op_num) => {
                const fromTaskIndex = op_num - 1;
                if (fromTaskIndex >= 0 && fromTaskIndex < userTasks.length) {
                    const fromTask = coloredTasks[fromTaskIndex];
                    const dep_type = userTasksMap[task.task].restriction_type || 'no_restriction';

                    dependencies.push({
                        from_task: fromTask.task,
                        to_task: task.task,
                        dependency_type: dep_type,
                    });
                }
            });
        }
    });

    const completedCount = userTasks.filter((task) => task.isCompleted).length;
    const totalTasks = userTasks.length;
    const completionPercent = totalTasks ? (completedCount / totalTasks) * 100 : 0;

    return (
        <div className={styles.pageWrapper}>
            <div className={styles.headerSection}>
                <h2 className={styles.title}>{workflow.workflow.name}</h2>
                <div className={styles.description}>{parse(workflow.workflow.description)}</div>

                <div className={styles.overallProgressContainer}>
                    <div className={styles.overallProgressHeader}>
                        <div className={styles.overallProgressLabel}>
                            {/* 5) Localize the “X of Y steps completed” */}
                            {t('workflowDetail.stepsCompleted', {
                                completed: completedCount,
                                total: totalTasks,
                            })}
                        </div>
                        <div className={styles.tooltipContainer}>
                            <span
                                className={styles.tooltipIcon}
                                tabIndex="0"
                                role="button"
                                aria-label="More info about progress"
                            >
                                ?
                            </span>
                            <div className={styles.tooltipText}>
                                {t('workflowDetail.tooltipInfo')}
                            </div>
                        </div>
                    </div>
                    <div className={styles.overallProgressBarBackground}>
                        <div
                            className={styles.overallProgressBarFill}
                            style={{ width: `${completionPercent}%` }}
                        />
                    </div>
                </div>
            </div>

            <WorkflowVisualization
                userTasks={coloredTasks}
                dependencies={dependencies}
                userTasksMap={userTasksMap}
                workflowId={id}
            />

            <div className={styles.tasksSection}>
                <WorkflowTasks
                    userTasks={userTasks}
                    workflowId={id}
                    userTasksMap={userTasksMap}
                />
            </div>
        </div>
    );
}

export default WorkflowDetail;
