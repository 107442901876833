// src/pages/AdminWorkflowList.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AdminWorkflowCard from '../../components/workflow/AdminWorkflowCard';
import config from '../../config';
import { FaPlus } from 'react-icons/fa';

import Styles from '../../styles/pages/AdminDashboard/AdminWorkflowList.module.css';

import DashboardTitle from '../../components/common/DashboardTitle';
import NavTabs from '../../components/common/NavTabs';
import { useTranslation } from 'react-i18next';

function AdminWorkflowList() {
  // 1) Initialize translation
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  const [workflows, setWorkflows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState('Manage');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/workflows/`, {
          headers: { Authorization: `Token ${localStorage.getItem('authToken')}` },
        });
        setWorkflows(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching workflows:', error);
        setLoading(false);
      }
    };
    fetchWorkflows();
  }, []);

  const handleDelete = (workflowId) => {
    // 2) Localize the confirm prompt
    if (window.confirm(t('adminWorkflowList.confirmDelete'))) {
      axios
        .delete(`${config.apiUrl}/workflows/${workflowId}`, {
          headers: { Authorization: `Token ${localStorage.getItem('authToken')}` },
        })
        .then(() => {
          setWorkflows((prev) => prev.filter((wf) => wf.id !== workflowId));
        })
        .catch((error) => {
          console.error('Failed to delete the workflow:', error);
        });
    }
  };

  const filteredWorkflows = workflows.filter((workflow) =>
    workflow.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleCreateWorkflow = () => {
    navigate('/admin-dashboard/create-workflow');
  };

  // 3) Localize the tab label
  const tabs = [
    { label: t('adminWorkflowList.manageTab'), link: '/admin-dashboard/workflows' },
  ];

  return (
    <div className={`${Styles.adminDashboardContainer} ${isArabic ? Styles.rtl : ''}`}>
      <div className={Styles.adminDashboardContent}>
        <div className={Styles.adminDashboardHeader}>
          <DashboardTitle
            title={t('adminWorkflowList.manageWorkflows')}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            searchPlaceholder={t('adminWorkflowList.searchPlaceholder')}
          />
          <NavTabs tabs={tabs} selectedTab={selectedTab} onTabSelect={setSelectedTab} />
        </div>

        <div className={Styles.workflowActions}>
          <button onClick={handleCreateWorkflow} className={Styles.createWorkflowBtn}>
            <FaPlus className={Styles.plusIcon} />
            {t('adminWorkflowList.createNewWorkflow')}
          </button>
        </div>

        <div className={Styles.adminWorkflowGrid}>
          {loading ? (
            <div className={Styles.loadingSection}>
              <div className={Styles.loadingSpinner}></div>
              <div className={Styles.loadingMessage}>{t('adminWorkflowList.loading')}</div>
            </div>
          ) : filteredWorkflows.length > 0 ? (
            filteredWorkflows.map((workflow) => (
              <AdminWorkflowCard
                key={workflow.id}
                workflow={workflow}
                onDelete={() => handleDelete(workflow.id)}
              />
            ))
          ) : (
            <div>{t('adminWorkflowList.noWorkflowsFound')}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminWorkflowList;
