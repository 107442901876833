import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Spinner,
  Alert,
  Collapse,
} from 'react-bootstrap';
import { FaPlus, FaChevronRight, FaChevronDown } from 'react-icons/fa';
import { motion } from 'framer-motion';
import config from '../../config';
import styles from '../../styles/pages/BrowseWorkflows/PreviewWorkflowDetails.module.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// 1) Import useTranslation
import { useTranslation } from 'react-i18next';

const PreviewWorkflowDetails = () => {
  const { t } = useTranslation(); // 2) Initialize translation hook

  const { id } = useParams();
  const navigate = useNavigate();
  const [workflow, setWorkflow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [addingWorkflow, setAddingWorkflow] = useState(false);
  const [expandedTasks, setExpandedTasks] = useState({});

  useEffect(() => {
    const fetchWorkflow = async () => {
      try {
        const authToken = localStorage.getItem('authToken');
        if (!authToken) {
          setError(t('previewWorkflows.authRequired'));
          setLoading(false);
          return;
        }
        const response = await axios.get(`${config.apiUrl}/workflows/${id}/`, {
          headers: { Authorization: `Token ${authToken}` },
        });
        setWorkflow(response.data);
      } catch (err) {
        setError(t('previewWorkflows.fetchFail'));
      } finally {
        setLoading(false);
      }
    };
    fetchWorkflow();
  }, [id, t]);

  const handleAddWorkflow = async () => {
    try {
      setAddingWorkflow(true);
      const authToken = localStorage.getItem('authToken');
      await axios.post(
        `${config.apiUrl}/user-workflows/`,
        { workflow_id: workflow.id, status: 'active' },
        { headers: { Authorization: `Token ${authToken}` } }
      );
      // Localize success toast
      toast.success(t('previewWorkflows.addedSuccess'));
    } catch (err) {
      setError(t('previewWorkflows.addFail'));
    } finally {
      setAddingWorkflow(false);
    }
  };

  const toggleTask = (index) => {
    setExpandedTasks((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  if (error) {
    return (
      <Container className={styles.container}>
        <Alert variant="danger" className="mt-4">
          {error}
        </Alert>
      </Container>
    );
  }

  return (
    <Container className={styles.container}>
      {loading ? (
        <div className={styles.loadingContainer}>
          <div className="loading-spinner"></div>
          <div className="loading-message">{t('previewWorkflows.loading')}</div>
        </div>
      ) : (
        <Row className="justify-content-center">
          <Col md={10}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Card className={styles.card}>
                {workflow && (
                  <>
                    <Card.Header className={styles.cardHeader}>
                      <h2 className={styles.cardTitle}>{workflow.name}</h2>
                    </Card.Header>
                    <Card.Body className={styles.cardBody}>
                      <p className={styles.description}>{workflow.description}</p>
                      {/* Localize "Tasks Included" */}
                      <h4 className={styles.tasksHeader}>{t('previewWorkflows.tasksIncluded')}</h4>
                      {workflow.tasks.length > 0 ? (
                        <div className={styles.tasksContainer}>
                          {workflow.tasks.map((task, index) => (
                            <div key={index} className={styles.taskItem}>
                              <div
                                className={styles.taskHeader}
                                onClick={() => toggleTask(index)}
                              >
                                {expandedTasks[index] ? (
                                  <FaChevronDown className={styles.taskIcon} />
                                ) : (
                                  <FaChevronRight className={styles.taskIcon} />
                                )}
                                <span className={styles.taskName}>
                                  {/* Use the interpolation syntax for "Task {index+1}: {task.name}" */}
                                  {t('previewWorkflows.taskLabel', {
                                    taskNumber: index + 1,
                                    taskName: task.name,
                                  })}
                                </span>
                              </div>
                              <Collapse in={expandedTasks[index]}>
                                <div className={styles.taskContent}>
                                  <p className={styles.taskDescription}>{task.description}</p>
                                </div>
                              </Collapse>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p className={styles.noTasks}>{t('previewWorkflows.noTasks')}</p>
                      )}
                    </Card.Body>

                    {error && (
                      <Alert variant="danger" className="m-3">
                        {error}
                      </Alert>
                    )}

                    <Card.Footer className={styles.cardFooter}>
                      <Button
                        variant="primary"
                        className={styles.addWorkflowButton}
                        onClick={handleAddWorkflow}
                        disabled={addingWorkflow}
                      >
                        {addingWorkflow ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />{' '}
                            {t('previewWorkflows.addingEllipsis')}
                          </>
                        ) : (
                          <>
                            <FaPlus /> {t('previewWorkflows.addToMyWorkflows')}
                          </>
                        )}
                      </Button>
                    </Card.Footer>
                  </>
                )}
              </Card>
            </motion.div>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default PreviewWorkflowDetails;
