// File: SignatureField.js

import React, { useState, useEffect } from 'react';
import { calculateLabelWidth } from './FieldTypesUtils'; // Reuse your utility if desired
import styles from '../../styles/components/FieldTypes/SignatureField.module.css';

export const SignatureFieldConfig = [
    "fieldLabel",
    "required",
    "allowUpload",
    "allowTyping",
    "help_text",
];
/**
 * SignatureField
 * Displays a tabbed interface with "Draw" (always), "Type" (optional), and "Upload" (optional).
 * Non-interactive in the admin's workflow builder — just a placeholder preview.
 */
function SignatureField({ field, onConfigChange, isSelected }) {
    const [label, setLabel] = useState(field.config.fieldLabel || 'Signature Field');
    const [helpText, setHelpText] = useState(field.config.help_text || '');
    
    // These booleans let us hide/show the Type & Upload tabs
    const allowTyping = !!field.config.allowTyping;
    const allowUpload = !!field.config.allowUpload;
    const isRequired = field.config.required;
  
    // Track the active tab. "draw" is always present; "type" and "upload" appear if enabled
    const [activeTab, setActiveTab] = useState('draw');
  
    // We only show these tabs if admin has enabled them
    const tabs = [
      { name: 'draw', label: 'Draw', always: true },
      { name: 'type', label: 'Type', always: false, enabled: allowTyping },
      { name: 'upload', label: 'Upload', always: false, enabled: allowUpload },
    ];
    const visibleTabs = tabs.filter(t => t.always || t.enabled);
  
    // Sync local label/helpText states with config changes
    useEffect(() => {
      setLabel(field.config.fieldLabel || 'Signature Field');
      setHelpText(field.config.help_text || '');
  
      // If you want to replicate the fancy asterisk placement from TextField:
      if (typeof calculateLabelWidth === 'function') {
        const labelWidth = calculateLabelWidth(field.config.fieldLabel || '');
        document.documentElement.style.setProperty('--label-length-' + field.id, labelWidth / 8);
      }
    }, [field.config, field.id]);
  
    // Handle label changes
    const handleLabelChange = (e) => {
      const updatedConfig = { ...field.config, fieldLabel: e.target.value };
      setLabel(e.target.value);
      onConfigChange(updatedConfig);
    };
  
    // Handle help text changes
    const handleHelpTextChange = (e) => {
      const updatedConfig = { ...field.config, help_text: e.target.value };
      setHelpText(e.target.value);
      onConfigChange(updatedConfig);
    };
  
    return (
      <div className={styles.signatureFieldWrapper}>
        {/* Label Section */}
        <div className={styles.labelWrapper}>
          <div className={styles.labelContainer}>
            <textarea
              className={`${styles.fieldLabel} ${isRequired ? styles.required : ''}`}
              value={label}
              placeholder={isSelected ? "Type a label" : ""}
              onChange={handleLabelChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  e.currentTarget.blur();
                }
              }}
              onInput={(e) => {
                e.target.style.height = 'auto';
                e.target.style.height = `${e.target.scrollHeight}px`;
              }}
              rows="1"
              wrap="soft"
            />
            {/* Position the required asterisk in a similar way to your TextField */}
            {isRequired && (
              <span
                style={{
                  left: `max(min(calc(10px + 1ch * var(--label-length-${field.id})), calc(100% - 40px)), calc(14%))`,
                  top: '5px',
                  position: 'absolute',
                  color: 'red',
                }}
              >
                *
              </span>
            )}
          </div>
        </div>
  
        {/* Tab headers */}
        <div className={styles.tabHeader}>
          {visibleTabs.map((tab) => (
            <button
              key={tab.name}
              className={`${styles.tabButton} ${activeTab === tab.name ? styles.activeTab : ''}`}
              onClick={() => setActiveTab(tab.name)}
            >
              {tab.label}
            </button>
          ))}
        </div>
  
        {/* Tab contents */}
        <div className={styles.tabContent}>
        {activeTab === 'draw' && (
        <div className={styles.drawTab}>
            {/* The signature pad container */}
            <div className={styles.signaturePadPlaceholder}>
            {/* "Sign Here" text + icon in the center */} 
            <div className={styles.signHereContainer}>
                <i className="fa fa-pen-fancy" aria-hidden="true"></i>
                <span>Sign Here</span>
            </div>

            {/* Grey line near the bottom */}
            <div className={styles.signatureLine}></div>

            {/* Clear button inside the pad */}
            <button className={styles.clearButton} disabled>
                Clear
            </button>
            </div>
        </div>
        )}
        
          {activeTab === 'type' && allowTyping && (
            <div className={styles.typeTab}>
              <input
                type="text"
                disabled
                placeholder="(Typed Signature Preview)"
                className={styles.typedSignaturePreview}
              />
              <div className={styles.fontStyles}>
                <select disabled>
                  <option>Font Style 1</option>
                  <option>Font Style 2</option>
                  <option>Font Style 3</option>
                </select>
              </div>
            </div>
          )}
  
          {activeTab === 'upload' && allowUpload && (
            <div className={styles.uploadTab}>
              <button className={styles.uploadButton} disabled>
                Choose File
              </button>
            </div>
          )}
        </div>
  
        {/* Editable Help Text Section */}
        <textarea
          className={styles.helpText}
          value={helpText}
          placeholder={isSelected ? "Type a help text" : ""}
          onChange={handleHelpTextChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.currentTarget.blur();
            }
          }}
          onInput={(e) => {
            e.target.style.height = 'auto';
            e.target.style.height = `${e.target.scrollHeight}px`;
          }}
          rows="1"
          wrap="soft"
        />
      </div>
    );
  }
  
  export default SignatureField;