import React, { useState, useEffect } from 'react';
import styles from '../../styles/components/FieldTypes/FieldTypes.module.css';
import { calculateLabelWidth } from './FieldTypesUtils';

// List of configuration keys for NumberField
export const NumberFieldConfig = [
  "fieldLabel",
  "required",
  "placeholder",
  "help_text",
  "min_value",
  "max_value",
  "default_value_number",
];

function NumberField({ field, onConfigChange, isSelected }) {
  const [label, setLabel] = useState(field.config.fieldLabel || "");
  const [placeholder, setPlaceholder] = useState(field.config.placeholder || "");
  const [helpText, setHelpText] = useState(field.config.help_text || "");
  const [minValue, setMinValue] = useState(field.config.min_value || 0);
  const [maxValue, setMaxValue] = useState(field.config.max_value || 100);
  const isRequired = field.config.required;

  useEffect(() => {
    setLabel(field.config.fieldLabel || "");
    setPlaceholder(field.config.placeholder || "");
    setHelpText(field.config.help_text || "");
    setMinValue(field.config.min_value || 0);
    setMaxValue(field.config.max_value || 100);

    const labelWidth = calculateLabelWidth(field.config.fieldLabel);
    document.documentElement.style.setProperty(
      `--label-length-${field.id}`,
      labelWidth / 8
    );

  }, [field.config]);

  // Handle field label change
  const handleLabelChange = (e) => {
    const updatedConfig = { ...field.config, fieldLabel: e.target.value };
    onConfigChange(updatedConfig);
  };

  // Handle help text change
  const handleHelpTextChange = (e) => {
    const updatedConfig = { ...field.config, help_text: e.target.value };
    onConfigChange(updatedConfig);
  };

  return (
    <div className={styles.numberField}>
      {/* Label Section */}
      <div className={styles.labelWrapper}>
        <div className={styles.labelContainer}>
          <textarea
            className={`${styles.fieldLabel} ${isRequired ? styles.required : ''}`}
            value={label}
            placeholder={isSelected ? "Type a label" : ""}
            onChange={handleLabelChange}
            rows="1"
          />
          {isRequired && (
            <span
              style={{
                left: `max(min(calc(10px + 1ch * var(--label-length-${field.id})), calc(100% - 40px)), calc(14%))`,
                top: '3px',
                position: 'absolute',
                color: 'red',
              }}
            >
              *
            </span>
          )}
        </div>
      </div>

      {/* Input Field Section */}
      <input
        className={styles.numberInput}
        type="number"
        placeholder={placeholder}
        min={minValue}
        max={maxValue}
        defaultValue={field.config.default_value_number || ""}
        disabled
      />

      {/* Editable Help Text Section */}
      <textarea
        className={styles.helpText}
        value={helpText}
        placeholder={isSelected ? "Type a help text" : ""}
        onChange={handleHelpTextChange}
        rows="1"
      />
    </div>
  );
}

export default NumberField;
