import React, { useRef, useLayoutEffect, useState, useMemo } from 'react';
import { FaCheck, FaLock, FaEye } from 'react-icons/fa';
import styles from '../../styles/components/WorkflowDetails/WorkflowVisualization.module.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

// Add workflowId prop and handleStepClick function
const WorkflowVisualization = ({ userTasks, dependencies, userTasksMap, workflowId }) => {
    const { t } = useTranslation();
    const containerRef = useRef(null);
    const [lines, setLines] = useState([]);

    console.log('tasks:', userTasks);
    console.log('dependencies:', dependencies);
    console.log('userTasksMap:', userTasksMap);

    // Adjust as desired
    const charWidth = 7;
    const maxChars = 50;
    const horizontalPadding = 20;
    const iconWidth = 10;


    const handleStepClick = (index) => {
        // Safety check in case index is out of bounds
        if (index < 0 || index >= userTasks.length) return;
        const restriction_type = userTasksMap[userTasks[index].task]?.restriction_type;
        console.log('restriction_type:', restriction_type);
        // get the restriction type of the clicked task
        if (restriction_type === 'must_submit_before_view') {
            // get the names of the previous tasks that are not completed
            const previousTasks = userTasks.slice(0, index).filter(task => !userTasksMap[task.task]?.completed);
            if (previousTasks.length > 0) {
                const taskNames = previousTasks.map(task => task.name).join(', ');
                toast.warning(`You must complete the following tasks before viewing this one: ${taskNames}`);
                return;
            }
        }

        const clickedTask = userTasks[index];
        const taskId = clickedTask.task;
        const url = `/user-dashboard/workflows/${workflowId}/step/${taskId}`;
        window.open(url, '_blank');
    };

    // Pre-process tasks for name truncation & indexing
    const processedTasks = useMemo(() => {
        return userTasks.map((t, idx) => {
            let displayName = t.name;
            if (displayName.length > maxChars) {
                displayName = displayName.slice(0, maxChars - 3) + '...';
            }
            return {
                ...t,
                displayName,
                index: idx  // used for click + animation delays
            };
        });
    }, [userTasks, maxChars]);

    // Compute line paths
    useLayoutEffect(() => {
        if (!containerRef.current) return;
        const measureAndSetLines = () => {

            const container = containerRef.current;
            const taskElements = {};

            processedTasks.forEach(t => {
                const el = container.querySelector(`[data-task-id='${t.task}']`);
                if (el) {
                    const rect = el.getBoundingClientRect();
                    const containerRect = container.getBoundingClientRect();
                    taskElements[t.task] = {
                        left: rect.left - containerRect.left,
                        top: rect.top - containerRect.top,
                        width: rect.width,
                        height: rect.height
                    };
                }
            });

            // Count lines from/to each task for offset
            const fromCounts = {};
            const toCounts = {};
            dependencies.forEach(dep => {
                fromCounts[dep.from_task] = (fromCounts[dep.from_task] || 0) + 1;
                toCounts[dep.to_task] = (toCounts[dep.to_task] || 0) + 1;
            });

            const fromAssigned = {};
            const toAssigned = {};

            const newLines = dependencies.map(dep => {
                const fromEl = taskElements[dep.from_task];
                const toEl = taskElements[dep.to_task];
                if (!fromEl || !toEl) return null;

                fromAssigned[dep.from_task] = (fromAssigned[dep.from_task] || 0) + 1;
                toAssigned[dep.to_task] = (toAssigned[dep.to_task] || 0) + 1;

                const fromCount = fromCounts[dep.from_task];
                const toCount = toCounts[dep.to_task];
                const fromIndex = fromAssigned[dep.from_task] - 1;
                const toIndex = toAssigned[dep.to_task] - 1;

                // Right edge of from_task, center Y
                const fromX = fromEl.left + fromEl.width;
                const fromYCenter = fromEl.top + fromEl.height / 2;
                // Left edge of to_task, center Y
                const toX = toEl.left;
                const toYCenter = toEl.top + toEl.height / 2;

                // Stagger lines if multiple from same task
                const offsetSpacing = 8;
                const fromOffset = (fromIndex - (fromCount - 1) / 2) * offsetSpacing;
                const toOffset = (toIndex - (toCount - 1) / 2) * offsetSpacing;

                const startY = fromYCenter + fromOffset;
                const endY = toYCenter + toOffset;

                // Curved path
                const controlOffset = 0.3; // 0.0 = almost straight lines, 0.5 = bigger curves
                const ctrlX1 = fromX + (toX - fromX) * controlOffset;
                const ctrlY1 = startY;
                const ctrlX2 = toX - (toX - fromX) * controlOffset;
                const ctrlY2 = endY;

                // So the path data becomes:
                const pathData = `M ${fromX},${startY} 
                                C ${ctrlX1},${ctrlY1} 
                                    ${ctrlX2},${ctrlY2} 
                                    ${toX},${endY}`;

                return { pathData };
            }).filter(Boolean);

            setLines(newLines);
        };

        // short delay (e.g., 50ms) to ensure layout is complete
        const timer = setTimeout(measureAndSetLines, 50);

        // cleanup
        return () => clearTimeout(timer);

    }, [processedTasks, dependencies]);

    // Provide tooltips for icons
    const getIconTooltip = (type) => {
        switch (type) {
            case 'completed':
                return 'Task Completed!';
            case 'locked':
                return 'Task Locked: Must Submit Previous Task Before Viewing.';
            case 'viewOnly':
                return 'View Only: You must complete the preceding tasks to open.';
            default:
                return '';
        }
    };

    // Determine icon
    const renderIconForTask = (task) => {
        const info = userTasksMap[task.task] || {};

        if (info.completed) {
            return (
                <FaCheck
                    className={styles.taskIcon}
                    aria-label="Completed"
                    title={getIconTooltip('completed')}
                />
            );
        } else if (info.restriction_type === 'must_submit_before_view') {
            return (
                <FaLock
                    className={styles.taskIcon}
                    aria-label="Locked"
                    title={getIconTooltip('locked')}
                />
            );
        } else if (info.restriction_type === 'can_view_only_before_submit') {
            return (
                <FaEye
                    className={styles.taskIcon}
                    aria-label="View Only"
                    title={getIconTooltip('viewOnly')}
                />
            );
        }
        return null;
    };

    // Group tasks by row
    const rows = [...new Set(processedTasks.map(t => t.row))];

    return (
        <div className={styles.visualizationContainer}>
            <h3 className={styles.visualizationTitle}>{t('workflowDetail.workflowOverview')}</h3>
            <div className={styles.ganttWrapper} ref={containerRef}>
                {/* Vertical lines */}
                {[...Array(Math.max(...processedTasks.map(t => t.start + t.length)) + 1)].map((_, i) => (
                    <div
                        key={i}
                        className={styles.verticalLine}
                        style={{ left: `calc(var(--time-step)*${i})` }}
                    />
                ))}

                {/* Rows and tasks */}
                {rows.map(rowIndex => (
                    <div key={rowIndex} className={styles.ganttRow}>
                        {processedTasks
                            .filter(t => t.row === rowIndex)
                            .map((task) => {
                                const info = userTasksMap[task.task] || {};
                                const classNames = [styles.ganttTask];

                                if (info.restriction_type === 'must_submit_before_view' && !info.completed) {
                                    classNames.push(styles.taskLocked);
                                } else if (info.restriction_type === 'can_view_only_before_submit' && !info.completed) {
                                    classNames.push(styles.taskViewOnly);
                                } else if (info.completed) {
                                    classNames.push(styles.taskCompleted);
                                }

                                // Bar width
                                const hasIcon = (
                                    info.completed ||
                                    info.restriction_type === 'must_submit_before_view' ||
                                    info.restriction_type === 'can_view_only_before_submit'
                                );
                                const iconSpace = hasIcon ? iconWidth + 5 : 0;
                                const displayWidth = (task.displayName.length * charWidth) + horizontalPadding + iconSpace;

                                return (
                                    <div
                                        key={task.task}
                                        data-task-id={task.task}
                                        className={classNames.join(' ')}
                                        style={{
                                            left: `calc(var(--time-step)*${task.start})`,
                                            width: `${displayWidth}px`,
                                            backgroundColor: task.color,
                                            // Stagger fade in
                                            animationDelay: `${task.index * 0.1}s`
                                        }}
                                        title={task.name}
                                        // *** Add clickable behavior here ***
                                        onClick={() => handleStepClick(task.index)}
                                    >
                                        {renderIconForTask(task)}
                                        <span className={styles.taskName}>
                                            {task.displayName}
                                        </span>
                                    </div>
                                );
                            })}
                    </div>
                ))}

                {/* SVG with circle markers at both start and end */}
                <svg
                    className={styles.arrowsOverlay}
                    width="100%"
                    height="100%"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        pointerEvents: 'none',
                        zIndex: 10
                    }}
                >
                    <defs>
                        {/* Dot at line start */}
                        <marker
                            id="lineStartMarker"
                            markerWidth="8"
                            markerHeight="8"
                            refX="4"
                            refY="4"
                            orient="auto"
                            fill="#2196f3"
                        >
                            <circle cx="4" cy="4" r="3" />
                        </marker>
                        {/* Dot at line end */}
                        <marker
                            id="lineEndMarker"
                            markerWidth="8"
                            markerHeight="8"
                            refX="4"
                            refY="4"
                            orient="auto"
                            fill="#2196f3"
                        >
                            <circle cx="4" cy="4" r="3" />
                        </marker>
                    </defs>
                    {lines.map((line, i) => (
                        <path
                            key={i}
                            className={styles.dependencyLine}
                            d={line.pathData}
                            fill="none"
                            stroke="#2196f3"
                            strokeWidth="2"
                            // Circle at both start & end
                            markerStart="url(#lineStartMarker)"
                            markerEnd="url(#lineEndMarker)"
                        />
                    ))}
                </svg>
            </div>
        </div>
    );
};

export default WorkflowVisualization;
