import React, { useState, useCallback, useMemo, useRef } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  Background,
  MiniMap,
  Controls,
  ConnectionLineType,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";

import { toast } from "react-toastify";
import { Plus, X } from "lucide-react";

import AnimatedEdge from "./AnimatedEdge";
import TaskNode from "./TaskNode";
import AddDependencyModal from "../Modals/AddDependencyModal";
import styles from "../../styles/components/WorkflowBuilder/AdminDependenciesView.module.css";
import { Lock, Eye, ArrowRightLeft } from "lucide-react";

/** Colors for edges */
const DEP_TYPE_COLORS = {
  must_submit_before_view: "#f44336",
  can_view_only_before_submit: "#ff9800",
  no_restriction: "#9e9e9e",
};

/** Icons & labels */
const TYPE_CONFIGS = {
  must_submit_before_view: {
    color: "#f44336",
    label: "Must Submit",
    icon: <Lock size={14} style={{ color: "#f44336" }} />,
  },
  can_view_only_before_submit: {
    color: "#ff9800",
    label: "Can View-Only without submit",
    icon: <Eye size={14} style={{ color: "#ff9800" }} />,
  },
  no_restriction: {
    color: "#9e9e9e",
    label: "No Restriction",
    icon: <ArrowRightLeft size={14} style={{ color: "#9e9e9e" }} />,
  },
};

/** A large palette for node colors */
const colorPalette = [
  "#00C48F", "#39AFD1", "#FFD966", "#AD75DB", "#FF8C66", "#FF5C5C", "#A05195", "#FF7C43",
  "#66578D", "#3F51B5", "#4CAF50", "#FFC107", "#FF5722", "#9C27B0", "#E91E63", "#03A9F4",
  "#00BCD4", "#009688", "#8BC34A", "#CDDC39", "#FFEB3B", "#FF9800", "#FF5722", "#795548",
  "#9E9E9E", "#607D8B",
  // repeat or add as many as you like...
];

function pickColor(i) {
  return colorPalette[i % colorPalette.length];
}

/** -- Move these OUTSIDE the component -- */
const nodeTypes = {
  taskNode: TaskNode,
};

const edgeTypes = {
  animatedEdge: AnimatedEdge,
};

export default function AdminDependenciesView({ tasks, dependencies, setDependencies }) {
  const flowWrapperRef = useRef(null);
  const { fitView } = useReactFlow();

  // 1) Build initial nodes
  const initNodes = tasks.map((t, i) => ({
    id: t.id,
    type: "taskNode",
    position: { x: 250 * i, y: 80 * i },
    data: {
      label: t.name,
      color: pickColor(i),
    },
  }));

  // 2) Build initial edges
  const initEdges = dependencies.map((dep) => {
    const color = DEP_TYPE_COLORS[dep.dependency_type] || "#2196f3";
    return {
      id: `${dep.from_task}->${dep.to_task}`,
      source: dep.from_task,
      target: dep.to_task,
      type: "animatedEdge",
      style: { stroke: color, strokeWidth: 3 },
      markerEnd: { type: "arrowclosed", color },
      data: { dependency_type: dep.dependency_type },
    };
  });

  const [nodes, setNodes, onNodesChange] = useNodesState(initNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initEdges);

  // 3) “Add Dependency” modal
  const [showAddModal, setShowAddModal] = useState(false);
  const openAddModal = () => setShowAddModal(true);
  const closeAddModal = () => setShowAddModal(false);

  // 4) If user draws a line => show center modal to pick type
  const [pendingEdge, setPendingEdge] = useState(null);
  const onConnect = useCallback(
    (params) => {
      // block self-connection
      if (params.source === params.target) {
        toast.warning("Cannot connect a task to itself!");
        return;
      }
      // block cycle
      if (createsCycle(params.source, params.target, edges)) {
        toast.error("Cannot create link, cycle detected!");
        return;
      }
      setPendingEdge(params);
    },
    [edges]
  );

  // finalize edge from center modal
  const finalizeEdge = (depType) => {
    if (!pendingEdge) return;
    const color = DEP_TYPE_COLORS[depType] || "#2196f3";
    const newEdge = {
      ...pendingEdge,
      id: `${pendingEdge.source}->${pendingEdge.target}`,
      type: "animatedEdge",
      style: { stroke: color, strokeWidth: 3 },
      markerEnd: { type: "arrowclosed", color },
      data: { dependency_type: depType },
    };
    setEdges((eds) => addEdge(newEdge, eds));
    setDependencies((prev) => [
      ...prev,
      {
        from_task: pendingEdge.source,
        to_task: pendingEdge.target,
        dependency_type: depType,
      },
    ]);
    toast.success("Dependency added!");
    setPendingEdge(null);
  };

  const cancelEdge = () => {
    setPendingEdge(null);
    toast.info("Connection canceled.");
  };

  // 5) For coloring the dots in the table
  const positions = useMemo(() => {
    const map = {};
    nodes.forEach((n) => {
      map[n.id] = { color: n.data.color };
    });
    return map;
  }, [nodes]);

  // 6) Remove or update type
  const removeDependency = (dep) => {
    // remove from edges
    setEdges((eds) => eds.filter((e) => e.id !== `${dep.from_task}->${dep.to_task}`));
    // remove from dependencies
    setDependencies((all) =>
      all.filter((d) => !(d.from_task === dep.from_task && d.to_task === dep.to_task))
    );
    toast.info("Dependency removed.");
  };

  const handleTypeChange = (dep, newType) => {
    // update dependencies
    setDependencies((all) =>
      all.map((d) => {
        if (d.from_task === dep.from_task && d.to_task === dep.to_task) {
          return { ...d, dependency_type: newType };
        }
        return d;
      })
    );
    // update edges
    const newColor = DEP_TYPE_COLORS[newType] || "#2196f3";
    setEdges((eds) =>
      eds.map((e) => {
        if (e.id === `${dep.from_task}->${dep.to_task}`) {
          return {
            ...e,
            style: { stroke: newColor, strokeWidth: 3 },
            markerEnd: { type: "arrowclosed", color: newColor },
            data: { dependency_type: newType },
          };
        }
        return e;
      })
    );
    toast.success("Dependency type updated!");
  };

  // 7) Prepare table data
  const currentDeps = useMemo(
    () =>
      dependencies.map((d, idx) => ({
        ...d,
        key: idx, // for React
      })),
    [dependencies]
  );

  // 8) Add from the “Add Dependency” modal
  const handleManualAdd = (from_task, to_task, depType) => {
    if (dependencies.some((x) => x.from_task === from_task && x.to_task === to_task)) {
      toast.warn("Dependency already exists!");
      return;
    }
    if (from_task === to_task) {
      toast.warn("Cannot link a task to itself!");
      return;
    }
    if (createsCycle(from_task, to_task, edges)) {
      toast.error("Cannot create link, cycle detected!");
      return;
    }
    // add to edges
    const color = DEP_TYPE_COLORS[depType] || "#2196f3";
    const newEdge = {
      id: `${from_task}->${to_task}`,
      source: from_task,
      target: to_task,
      type: "animatedEdge",
      style: { stroke: color, strokeWidth: 3 },
      markerEnd: { type: "arrowclosed", color },
      data: { dependency_type: depType },
    };
    setEdges((eds) => [...eds, newEdge]);
    // add to dependencies
    setDependencies((prev) => [
      ...prev,
      { from_task, to_task, dependency_type: depType },
    ]);
    toast.success("Dependency added!");
  };

  // Fit to view
  const handleFitView = () => {
    fitView({ padding: 0.2 });
  };

  return (
    <div className={styles.outerContainer}>
      {/* LEFT: Dependencies Table */}
      <div className={styles.leftPanel}>
        <h3 className={styles.depHeader}>Dependencies</h3>

        <div className={styles.tableWrapper}>
          <table className={styles.depTable}>
            <thead>
              <tr>
                <th>From Task</th>
                <th>Type</th>
                <th>To Task</th>
                <th></th> {/* for the remove button */}
              </tr>
            </thead>
            <tbody>
              {currentDeps.map((dep) => {
                const fromTask = tasks.find((t) => t.id === dep.from_task);
                const toTask = tasks.find((t) => t.id === dep.to_task);

                const fromName = fromTask ? fromTask.name : dep.from_task;
                const toName = toTask ? toTask.name : dep.to_task;
                const typeCfg = TYPE_CONFIGS[dep.dependency_type] || {
                  color: "#2196f3",
                  label: "Unknown",
                  icon: null,
                };

                return (
                  <tr key={dep.key}>
                    <td>
                      <div className={styles.taskWithDot}>
                        <span
                          className={styles.taskDot}
                          style={{
                            backgroundColor: positions[dep.from_task]?.color || "#ccc",
                          }}
                        />
                        {fromName}
                      </div>
                    </td>
                    <td>
                      <div
                        className={styles.typePill}
                        style={{ backgroundColor: `${typeCfg.color}1A` }}
                      >
                        {typeCfg.icon}
                        <select
                          className={styles.typeSelect}
                          value={dep.dependency_type}
                          onChange={(e) => handleTypeChange(dep, e.target.value)}
                          style={{ color: typeCfg.color }}
                        >
                          {Object.entries(TYPE_CONFIGS).map(([k, v]) => (
                            <option key={k} value={k}>
                              {v.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </td>
                    <td>
                      <div className={styles.taskWithDot}>
                        <span
                          className={styles.taskDot}
                          style={{
                            backgroundColor: positions[dep.to_task]?.color || "#ccc",
                          }}
                        />
                        {toName}
                      </div>
                    </td>
                    <td>
                      <button
                        className={styles.removeBtn}
                        onClick={() => removeDependency(dep)}
                        title="Remove"
                      >
                        <X size={16} />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <button className={styles.addDepBtn} onClick={openAddModal}>
          <Plus size={16} /> Add Dependency
        </button>
      </div>

      {/* RIGHT: React Flow Area */}
      <div className={styles.rightPanel} ref={flowWrapperRef}>
        <ReactFlow
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          connectionLineType={ConnectionLineType.Bezier}
          fitView
          fitViewOptions={{ padding: 0.2 }}
        >
          <Background variant="dots" gap={12} size={1} />
          <MiniMap />
          <Controls />
        </ReactFlow>

        {/* Legend pinned top-left */}
        <div className={styles.legendBox}>
          <strong>Dependency Types</strong>
          <div className={styles.legendItem}>
            <span
              className={styles.legendColor}
              style={{ backgroundColor: DEP_TYPE_COLORS.must_submit_before_view }}
            />
            Must Submit
            <small style={{ color: "#666", marginLeft: "4px" }}>
              (User cannot view or fill the next task until they submit the current one)
            </small>
          </div>
          <div className={styles.legendItem}>
            <span
              className={styles.legendColor}
              style={{ backgroundColor: DEP_TYPE_COLORS.can_view_only_before_submit }}
            />
            View-Only
            <small style={{ color: "#666", marginLeft: "4px" }}>
              (User can only view the next task until the previous one is submitted)
            </small>
          </div>
          <div className={styles.legendItem}>
            <span
              className={styles.legendColor}
              style={{ backgroundColor: DEP_TYPE_COLORS.no_restriction }}
            />
            No Restriction
            <small style={{ color: "#666", marginLeft: "4px" }}>
              (User can view and fill the next task at any time)
            </small>
          </div>
        </div>

        {/* Fit to View button */}
        <button className={styles.fitBtn} onClick={handleFitView}>
          Fit to View
        </button>
      </div>

      {/* Center-screen modal if user draws an edge */}
      {pendingEdge && (
        <div className={styles.centerOverlay} onClick={cancelEdge}>
          <div className={styles.centerModal} onClick={(e) => e.stopPropagation()}>
            <h2>Select Dependency Type</h2>
            <p style={{ marginBottom: "16px" }}>For the new connection:</p>
            <div className={styles.centerChoices}>
              <div
                className={styles.centerChoice}
                style={{ backgroundColor: DEP_TYPE_COLORS.must_submit_before_view }}
                onClick={() => finalizeEdge("must_submit_before_view")}
              >
                Must Submit Before View
              </div>
              <div
                className={styles.centerChoice}
                style={{ backgroundColor: DEP_TYPE_COLORS.can_view_only_before_submit }}
                onClick={() => finalizeEdge("can_view_only_before_submit")}
              >
                View-Only Before Submit
              </div>
              <div
                className={styles.centerChoice}
                style={{ backgroundColor: DEP_TYPE_COLORS.no_restriction }}
                onClick={() => finalizeEdge("no_restriction")}
              >
                No Restriction
              </div>
            </div>
            <button className={styles.centerCancel} onClick={cancelEdge}>
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* “Add Dependency” Modal */}
      {showAddModal && (
        <AddDependencyModal
          tasks={tasks}
          onClose={closeAddModal}
          onAdd={(from_task, to_task, type) => handleManualAdd(from_task, to_task, type)}
        />
      )}
    </div>
  );
}

/** Simple cycle-check with adjacency + DFS */
function createsCycle(sourceId, targetId, edges) {
  const adjacency = {};
  edges.forEach((e) => {
    if (!adjacency[e.source]) adjacency[e.source] = [];
    adjacency[e.source].push(e.target);
  });

  if (!adjacency[sourceId]) adjacency[sourceId] = [];
  adjacency[sourceId].push(targetId);

  const visited = new Set();
  function dfs(node) {
    if (node === sourceId) return true;
    visited.add(node);
    const neighbors = adjacency[node] || [];
    for (const nxt of neighbors) {
      if (!visited.has(nxt)) {
        if (dfs(nxt)) return true;
      }
    }
    return false;
  }
  return dfs(targetId);
}
