import React, { useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import styles from '../../styles/components/WorkflowBuilder/Toolbar.module.css';

export const toolbarTabs = {
  Basic: [
    { id: 'text', type: 'text', label: 'Text', iconClass: 'fa fa-font' },
    { id: 'number', type: 'number', label: 'Number', iconClass: 'fa fa-hashtag' },
    { id: 'spinner', type: 'spinner', label: 'Dropdown', iconClass: 'fa fa-caret-down' },
    { id: 'checkbox', type: 'checkbox', label: 'Checkbox', iconClass: 'fa fa-check-square' },
    { id: 'radiobutton', type: 'radiobutton', label: 'Single Choice (Radio)', iconClass: 'fa fa-dot-circle' },
    { id: 'MultiSelect', type: 'multi_select_checkboxes', label: 'Multi Select', iconClass: 'fa fa-list' },
    
    { id: 'date', type: 'Date', label: 'date', iconClass: 'fa fa-calendar' },
    { id: 'file_upload', type: 'file_upload', label: 'File Upload', iconClass: 'fa fa-file' },


  ],

  Widget: [
    
    { id: 'signature', type: 'signature', label: 'Signature', iconClass: 'fa fa-signature' },
  ],

  Templates: [],
};

const Toolbar = ({ isOpen, toggleToolbar, adjusted  }) => {
  const [activeTab, setActiveTab] = useState('Basic');

  return (
    <>
      {/* Toggle Button to open/close the toolbar */}
      <button className={`${styles.openButton} ${isOpen ? styles.hidden : ''}`} onClick={toggleToolbar}>
        <i className="fa fa-plus"></i> Add Element
      </button>

      {/* Toolbar itself */}
      <div className={`${styles.toolbar} ${isOpen ? styles.open : styles.closed} ${adjusted ? styles.adjusted : ''}`}>
        {/* Header with title and close button */}
        <div className={styles.header}>
          <h3>Workflow Elements</h3>
          <button className={styles.closeButton} onClick={toggleToolbar}>
            <i className="fa fa-times"></i>
          </button>
        </div>

        {/* Tabs for switching between Basic, Widget, and Templates */}
        <div className={styles.tabs}>
          <button
            className={activeTab === 'Basic' ? styles.active : ''}
            onClick={() => setActiveTab('Basic')}
          >
            Basic
          </button>
          <button
            className={activeTab === 'Widget' ? styles.active : ''}
            onClick={() => setActiveTab('Widget')}
          >
            Widget
          </button>
          <button
            className={activeTab === 'Templates' ? styles.active : ''}
            onClick={() => setActiveTab('Templates')}
          >
            Templates
          </button>
        </div>

        {/* Content of the active tab with draggable items */}
        <div className={styles.content}>
          <Droppable droppableId="toolbar" type="field" isDropDisabled={true}>
            {(provided) => (
              <div
                className={styles.tabContent}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {toolbarTabs[activeTab].length > 0 ? (
                  toolbarTabs[activeTab].map((field, index) => (
                    <Draggable key={field.id} draggableId={`toolbar-${field.id}`} index={index}>
                      {(provided) => (
                        <div
                          className={styles.toolbarItem}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <i className={field.iconClass} aria-hidden="true"></i>
                          <span>{field.label}</span>
                        </div>
                      )}
                    </Draggable>
                  ))
                ) : (
                  <p className={styles.comingSoon}>Coming Soon...</p>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </>
  );
};


export default Toolbar;
