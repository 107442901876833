import React from 'react';
import { Link } from 'react-router-dom';
import { FaTrashAlt, FaUsers, FaCheck } from 'react-icons/fa';
import { MdModeEditOutline } from 'react-icons/md';

// Updated/renamed CSS import
import Styles from '../../styles/components/WorkflowDetails/AdminWorkflowCard.module.css';

// 1) Import the translation hook
import { useTranslation } from 'react-i18next';

function AdminWorkflowCard({ workflow, onDelete }) {
  const { t } = useTranslation(); // 2) Initialize translation

  const workflowInfo = workflow;

  // If there's no image_url, we fallback to a placeholder that references a localized text
  const imageUrl =
    workflowInfo.image_url ||
    `https://via.placeholder.com/300x200.png?text=${encodeURIComponent(
      t('adminWorkflowCard.noImage')
    )}`;

  // Category fallback
  const category = workflowInfo.category || t('adminWorkflowCard.uncategorized');

  // Description fallback
  const description = workflowInfo.description || t('adminWorkflowCard.noDescription');

  // For "Done" or zero
  const doneCount = workflow.done_count || 0;

  const handleDelete = () => {
    onDelete(workflow.id);
  };

  return (
    <div className={Styles.cardContainer}>
      {/* Header / Image */}
      <div className={Styles.cardHeader}>
        <img
          src={imageUrl}
          alt={workflowInfo.name}
          className={Styles.cardImage}
        />
      </div>

      {/* Body */}
      <div className={Styles.cardBody}>
        <h2 className={Styles.cardTitle}>
          {workflowInfo.name}
          <Link
            to={`/admin-dashboard/edit-workflow/workflows/${workflow.id}`}
            className={Styles.editIcon}
            title={t('adminWorkflowCard.editWorkflow')}
          >
            <MdModeEditOutline size={18} />
          </Link>
        </h2>

        {/* Category "pill" */}
        <span className={Styles.categoryPill}>
          {category}
        </span>

        <p className={Styles.cardDescription}>
          {description}
        </p>
      </div>

      {/* Footer pinned at bottom */}
      <div className={Styles.cardFooter}>
        <button
          className={Styles.deleteButton}
          onClick={handleDelete}
        >
          <FaTrashAlt /> {t('adminWorkflowCard.deleteBtn')}
        </button>

        <Link
          to={`/admin-dashboard/view/workflows/${workflow.id}`}
          state={{ workflowName: workflowInfo.name }}
          className={Styles.viewButton}
        >
          <FaUsers /> {t('adminWorkflowCard.viewSubmissions')}
        </Link>
      </div>

      {/* Additional bottom stats (if desired) */}
      <div className={Styles.bottomStats}>
        <div className={Styles.userCount}>
          <FaUsers /> {workflow.user_count}
        </div>
        <div className={Styles.doneCount}>
          <FaCheck /> {doneCount}
        </div>
      </div>
    </div>
  );
}

export default AdminWorkflowCard;
