import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  FiChevronDown,
  FiChevronUp,
  FiSend,
  FiCheck,
  FiXCircle,
  FiEdit,
  FiClock,
} from 'react-icons/fi';
import { Container } from 'react-bootstrap';
import styles from '../../styles/pages/AdminDashboard/ViewUserTasks.module.css';

import { toast } from 'react-toastify';
import config from '../../config';
import axios from 'axios';

// 1) Import the translation hook
import { useTranslation } from 'react-i18next';

const approveTask = async (task, t) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await fetch(`${config.apiUrl}/tasks/${task.id}/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({ status: 'Approved' }),
    });
    if (!response.ok) {
      throw new Error(t('viewUserTasks.approveError'));
    }
    toast.success(
      t('viewUserTasks.approvedToast', { taskName: task.name })
    );
  } catch (err) {
    toast.error(err.message || t('viewUserTasks.approveErrGeneric'));
  }
};

const rejectTask = async (task, t) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await fetch(`${config.apiUrl}/tasks/${task.id}/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({ status: 'Rejected' }),
    });
    if (!response.ok) {
      throw new Error(t('viewUserTasks.rejectError'));
    }
    toast.success(
      t('viewUserTasks.rejectedToast', { taskName: task.name })
    );
  } catch (err) {
    toast.error(err.message || t('viewUserTasks.rejectErrGeneric'));
  }
};

const getStatusPill = (status = '', t) => {
  const normalized = status.toLowerCase();
  if (normalized === 'submitted') {
    return (
      <span className={`${styles.statusPill} ${styles.statusSubmitted}`}>
        <FiSend className={styles.statusIcon} />
        {t('viewUserTasks.submittedPill')}
      </span>
    );
  } else if (['approved', 'done', 'completed'].includes(normalized)) {
    return (
      <span className={`${styles.statusPill} ${styles.statusApproved}`}>
        <FiCheck className={styles.statusIcon} />
        {t('viewUserTasks.approvedPill')}
      </span>
    );
  } else if (normalized === 'rejected') {
    return (
      <span className={`${styles.statusPill} ${styles.statusRejected}`}>
        <FiXCircle className={styles.statusIcon} />
        {t('viewUserTasks.rejectedPill')}
      </span>
    );
  } else if (normalized === 'draft') {
    return (
      <span className={`${styles.statusPill} ${styles.statusNotSubmitted}`}>
        <FiEdit className={styles.statusIcon} />
        {t('viewUserTasks.draftPill')}
      </span>
    );
  }
  // fallback => "Not Submitted"
  return (
    <span className={`${styles.statusPill} ${styles.statusNotSubmitted}`}>
      <FiClock className={styles.statusIcon} />
      {t('viewUserTasks.notSubmittedPill')}
    </span>
  );
};

const ViewUserTasks = () => {
  const { t } = useTranslation(); // 2) use translation
  const location = useLocation();
  const navigate = useNavigate();
  const { userWorkflow, workflowId } = location.state || {};
  const [expandedTaskIds, setExpandedTaskIds] = useState([]);

  if (!userWorkflow) {
    return <div className={styles.noData}>{t('viewUserTasks.noTasksToDisplay')}</div>;
  }

  const userTasks = userWorkflow.user_tasks || [];
  if (userTasks.length === 0) {
    return <div className={styles.noData}>{t('viewUserTasks.noTasksAvailable')}</div>;
  }

  const totalTasks = userTasks.length;
  const submittedCount = userTasks.filter(
    (task) => task.num_filled_fields === task.num_fields
  ).length;
  const approvedCount = userTasks.filter((task) =>
    ['approved', 'done', 'completed'].includes((task.status || '').toLowerCase())
  ).length;
  const rejectedCount = userTasks.filter((task) =>
    (task.status || '').toLowerCase() === 'rejected'
  ).length;

  const toggleExpand = (taskId) => {
    setExpandedTaskIds((prev) =>
      prev.includes(taskId) ? prev.filter((id) => id !== taskId) : [...prev, taskId]
    );
  };

  const handleBackClick = () => {
    navigate(`/admin-dashboard/view/workflows/${workflowId}`);
  };

  return (
    <div className={styles.wrapper}>
      <Container fluid>
        <div className={styles.topBar}>
          <button className={styles.backBtn} onClick={handleBackClick}>
            {t('viewUserTasks.backToSubmissions')}
          </button>
          <div className={styles.userWorkflowInfo}>
            <h2 className={styles.userName}>{userWorkflow.user_name}</h2>
            <p className={styles.workflowName}>{userWorkflow.workflow.name}</p>
          </div>
        </div>

        {/* The 4 circle stats */}
        <div className={styles.taskStatsRow}>
          {/* Circle #1: Total Tasks */}
          <div className={styles.circleCard}>
            <div className={`${styles.circle} ${styles.circleBlack}`}>
              <span className={styles.circleNumber}>{totalTasks}</span>
            </div>
            <p className={`${styles.circleLabel} ${styles.animateLabel}`}>
              {t('viewUserTasks.totalTasks')}
            </p>
          </div>

          {/* Circle #2: Submitted */}
          <div className={styles.circleCard}>
            <div className={`${styles.circle} ${styles.circleBlue}`}>
              <span className={styles.circleNumber}>{submittedCount}</span>
            </div>
            <p className={`${styles.circleLabel} ${styles.animateLabel}`}>
              {t('viewUserTasks.submitted')}
            </p>
          </div>

          {/* Circle #3: Approved */}
          <div className={styles.circleCard}>
            <div className={`${styles.circle} ${styles.circleGreen}`}>
              <span className={styles.circleNumber}>{approvedCount}</span>
            </div>
            <p className={`${styles.circleLabel} ${styles.animateLabel}`}>
              {t('viewUserTasks.approved')}
            </p>
          </div>

          {/* Circle #4: Rejected */}
          <div className={styles.circleCard}>
            <div className={`${styles.circle} ${styles.circleRed}`}>
              <span className={styles.circleNumber}>{rejectedCount}</span>
            </div>
            <p className={`${styles.circleLabel} ${styles.animateLabel}`}>
              {t('viewUserTasks.rejected')}
            </p>
          </div>
        </div>
      </Container>

      {/* Task List */}
      <div className={styles.taskListContainer}>
        {userTasks.map((task, index) => {
          const isSubmitted = task.num_filled_fields === task.num_fields;
          const isExpanded = expandedTaskIds.includes(task.id);

          return (
            <div key={task.id} className={styles.taskCard}>
              <div className={`${styles.cardHeader} ${!isSubmitted ? styles.disabledCard : ''}`}>
                <div className={styles.headerInfo}>
                  {/* Step label with placeholders */}
                  <h3 className={styles.taskTitle}>
                    {t('viewUserTasks.stepLabel', {
                      stepNumber: index + 1,
                      taskName: task.name,
                    })}
                  </h3>
                  <p className={styles.taskDescription}>
                    {task.description || t('viewUserTasks.placeholderDescription')}
                  </p>

                  {isSubmitted && (
                    <button
                      className={styles.viewDetailsButton}
                      onClick={() => toggleExpand(task.id)}
                    >
                      {t('viewUserTasks.viewDetails')}
                      {isExpanded ? <FiChevronUp /> : <FiChevronDown />}
                    </button>
                  )}
                </div>
                <div className={styles.headerRight}>
                  {getStatusPill(task.status, t)}
                </div>
              </div>

              {isSubmitted && isExpanded && (
                <div className={styles.expandedContainer}>
                  <div className={styles.fieldsContainer}>
                    {(task.user_task_fields || []).map((field) => (
                      <div key={field.id} className={styles.fieldRow}>
                        <label className={styles.fieldLabel}>{field.fieldName}</label>
                        <input
                          type="text"
                          className={styles.fieldInput}
                          value={field.fieldValue}
                          readOnly
                          disabled
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {isSubmitted && (
                <div className={styles.cardFooter}>
                  <button
                    className={`${styles.btn} ${styles.btnApprove}`}
                    onClick={() => approveTask(task, t)}
                  >
                    <FiCheck className={styles.btnIcon} />
                    {t('viewUserTasks.approveBtn')}
                  </button>
                  <button
                    className={`${styles.btn} ${styles.btnReject}`}
                    onClick={() => rejectTask(task, t)}
                  >
                    <FiXCircle className={styles.btnIcon} />
                    {t('viewUserTasks.rejectBtn')}
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ViewUserTasks;
