import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';

import styles from '../../styles/pages/UserDashboard/WorkflowStepDetail.module.css';
import { MultiStepForm } from '../../components/WorkflowDetails/MultiStepForm';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function WorkflowStepDetail() {
  const { t } = useTranslation();

  const { id, stepId } = useParams();
  const navigate = useNavigate();

  const [task, setTask] = useState(null);
  const [userTask, setUserTask] = useState(null);
  const [fieldsCompleted, setFieldsCompleted] = useState(0);
  const [totalFields, setTotalFields] = useState(0);
  const [workflowTasks, setWorkflowTasks] = useState([]);

  const [taskDataLoaded, setTaskDataLoaded] = useState(false);
  const [workflowTasksLoaded, setWorkflowTasksLoaded] = useState(false);

  // Reference to call validation in MultiStepForm
  const multiStepFormRef = useRef(null);

  useEffect(() => {
    setTask(null);
    setUserTask(null);
    setFieldsCompleted(0);
    setTotalFields(0);
    setWorkflowTasks([]);
    setTaskDataLoaded(false);
    setWorkflowTasksLoaded(false);
  }, [id, stepId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = localStorage.getItem('authToken');
        const response = await axios.get(`${config.apiUrl}/user-workflows/${id}/`, {
          headers: { 'Authorization': `Token ${authToken}` }
        });

        console.log('Fetched workflow details:', response.data);

        const fetchedWorkflowTasks = response.data.workflow.tasks;
        setWorkflowTasks(fetchedWorkflowTasks);
        const taskDetail = fetchedWorkflowTasks.find(t => `${t.id}` === stepId);
        if (!taskDetail) {
          navigate(`/user-dashboard/workflows/${id}`);
          return;
        }
        setTask(taskDetail);

        const uTask = response.data.user_tasks.find(t => `${t.task}` === stepId);
        if (!uTask) {
          navigate(`/user-dashboard/workflows/${id}`);
          return;
        }
        setUserTask(uTask);

        if (uTask.user_task_fields) {
          setTotalFields(uTask.user_task_fields.length);
          const filledCount = uTask.user_task_fields.filter(f => f.fieldValue).length;
          setFieldsCompleted(filledCount);
        }

        setTaskDataLoaded(true);
        setWorkflowTasksLoaded(true);
      } catch (error) {
        console.error('Failed to fetch task details:', error);
        navigate(`/user-dashboard/workflows/${id}`);
      }
    };

    fetchData();
  }, [id, stepId, navigate]);

  const handlePageUpdate = (step, data) => {
    let filledCount = 0;
    Object.values(data).forEach(value => {
      if (value && value.trim() !== '') filledCount++;
    });
    setFieldsCompleted(filledCount);
  };

  const allDataLoaded = taskDataLoaded && workflowTasksLoaded;
  const currentIndex = (allDataLoaded && workflowTasks.length > 0 && task)
    ? workflowTasks.findIndex(t => t.id === task.id)
    : null;

  if (allDataLoaded && (currentIndex === -1 || currentIndex === null)) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loadingText}>{t('workflowStepDetail.invalidTaskData')}</div>
      </div>
    );
  }

  const isFirstStep = allDataLoaded && currentIndex === 0;
  const isLastStep = allDataLoaded && currentIndex === workflowTasks.length - 1;

  const handleCircleClick = (taskIndex) => {
    const taskId = workflowTasks[taskIndex].id;
    navigate(`/user-dashboard/workflows/${id}/step/${taskId}`);
  };

  const handlePrevious = () => {
    if (!isFirstStep) {
      const prevTaskId = workflowTasks[currentIndex - 1].id;
      navigate(`/user-dashboard/workflows/${id}/step/${prevTaskId}`);
    }
  };

  const handleNext = () => {
    if (!isLastStep) {
      const nextTaskId = workflowTasks[currentIndex + 1].id;
      navigate(`/user-dashboard/workflows/${id}/step/${nextTaskId}`);
    }
  };

  const handleSubmit = () => {
    navigate(`/user-dashboard/workflows/${id}`);
  };

  const handleTaskSubmit = () => {
    // Call the validation function on MultiStepForm
    if (multiStepFormRef.current) {
      const isValid = multiStepFormRef.current.validateFields();
      if (!isValid) {
        // If not valid, MultiStepForm should show error indicators
        console.warn('Some fields are invalid. Please correct them before proceeding.');
        toast.error('Some fields are invalid. Please correct them before proceeding.');
      } else {
        console.log('Task fields are valid!');
        // Todo: Submit the task data to the server
      }
    }
  };

  const progress = totalFields > 0 ? Math.round((fieldsCompleted / totalFields) * 100) : 0;

  return (
    <div className={styles.container}>
      <div className={styles.topBar}>
        <button
          className={styles.backLinkButtonTop}
          onClick={() => navigate(`/user-dashboard/workflows/${id}`)}
          aria-label={t('workflowStepDetail.backToWorkflow')}
        >
          ← {t('workflowStepDetail.backToWorkflow')}
        </button>
      </div>

      {workflowTasks.length > 0 && (
        <div className={styles.stepNav}>
          <div className={styles.stepLine}>
            {workflowTasks.map((wt, i) => {
              const isCurrent = allDataLoaded && i === currentIndex;
              const circleClass = isCurrent
                ? styles.stepCircleCurrent
                : styles.stepCircleFuture;
              return (
                <div
                  key={wt.id}
                  className={styles.stepItem}
                  onClick={() => handleCircleClick(i)}
                  title={wt.name}
                  role="button"
                  tabIndex={0}
                  aria-label={`Go to step ${i + 1}: ${wt.name}`}
                  onKeyDown={(e) => { if (e.key === 'Enter') handleCircleClick(i); }}
                >
                  <div className={circleClass}>
                    {i + 1}
                  </div>
                  {i < workflowTasks.length - 1 && <div className={styles.stepConnector} />}
                </div>
              );
            })}
          </div>
        </div>
      )}

      <div className={styles.pageWrapper}>
        <div className={styles.formContainer}>
          {allDataLoaded ? (
            <MultiStepForm
              ref={multiStepFormRef}
              task={task}
              step={parseInt(stepId, 10) - 1}
              onPageUpdate={handlePageUpdate}
              workflowId={id}
              userTask={userTask}
            />
          ) : (
            <div className={styles.transitionInForm}>
              <div className={styles.loader}></div>
              <div className={styles.loadingText}>{t('workflowStepDetail.loadingStep')}</div>
            </div>
          )}
        </div>

        {allDataLoaded && (
          <div className={styles.footerNav}>
            {!isFirstStep ? (
              <button
                className={`${styles.navButton} ${styles.prevButton}`}
                onClick={handlePrevious}
                aria-label={t('workflowStepDetail.previous')}
              >
                ← {t('workflowStepDetail.previous')}
              </button>
            ) : (
              <div></div>
            )}

            {/* Submit button for the current task */}
            <button
              className={`${styles.navButton} ${styles.submitButton}`}
              onClick={handleTaskSubmit}
              aria-label={t('workflowStepDetail.submitTask')}
            >
              {t('workflowStepDetail.submitTask')}
            </button>

            {!isLastStep && (
              <button
                className={`${styles.navButton} ${styles.nextButton}`}
                onClick={handleNext}
                aria-label={t('workflowStepDetail.next')}
              >
                {t('workflowStepDetail.next')} →
              </button>
            )}

            {isLastStep && (
              <button
                className={`${styles.navButton} ${styles.submitButton}`}
                onClick={handleSubmit}
                aria-label={t('workflowStepDetail.submitWorkflow')}
              >
                {t('workflowStepDetail.submitWorkflow')}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default WorkflowStepDetail;
