import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  FiUsers,
  FiUserCheck,
  FiTrendingUp,
  FiClipboard,
  FiClock,
  FiCheckCircle,
} from 'react-icons/fi';

import config from '../../config';
import styles from '../../styles/pages/AdminDashboard/ViewWorkflow.module.css';

// 1) Import the translation hook
import { useTranslation } from 'react-i18next';

const ViewWorkflow = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // If no workflowName in state, fallback to ''
  const [workflowName] = useState(location.state?.workflowName || '');

  const [workflowUsers, setWorkflowUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const [totalUsers, setTotalUsers] = useState(0);
  const [completedUsers, setCompletedUsers] = useState(0);

  const [avgProgress, setAvgProgress] = useState(0);
  const [activeTasks, setActiveTasks] = useState(0);

  useEffect(() => {
    const fetchWorkflowUsers = async () => {
      try {
        const authToken = localStorage.getItem('authToken');
        if (!authToken) {
          throw new Error(t('viewWorkflow.unknownError'));
        }
        const response = await axios.get(`${config.apiUrl}/workflows/${id}/users`, {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        });
        const userData = response.data || [];

        setWorkflowUsers(userData);
        setLoading(false);

        // Some sample stats calculations:
        const allUsersCount = userData.length;
        const completedCount = userData.filter(
          (u) => u.num_completed_tasks === u.num_tasks
        ).length;
        const averageProgress = calculateAverageProgress(userData);
        const tasksCount = calculateActiveTasks(userData);

        setTotalUsers(allUsersCount);
        setCompletedUsers(completedCount);
        setAvgProgress(averageProgress);
        setActiveTasks(tasksCount);
      } catch (err) {
        // 3) Localize the error message
        const errorMessage =
          err.response?.data?.message || err.message || t('viewWorkflow.unknownError');
        setError(t('viewWorkflow.fetchErrorPrefix') + errorMessage);
        setLoading(false);
      }
    };

    fetchWorkflowUsers();
  }, [id, workflowName, t]);

  const calculateAverageProgress = (users) => {
    if (!users.length) return 0;
    let total = 0;
    let count = 0;
    users.forEach((u) => {
      const tasks = u.num_tasks || 0;
      const done = u.num_completed_tasks || 0;
      if (tasks > 0) {
        total += done / tasks;
        count++;
      }
    });
    return count ? Math.round((total / count) * 100) : 0;
  };

  const calculateActiveTasks = (users) => {
    let sum = 0;
    users.forEach((u) => {
      const tasks = u.num_tasks || 0;
      const done = u.num_completed_tasks || 0;
      sum += tasks - done;
    });
    return sum;
  };

  const handleViewTasks = (workflowId, userId, userWorkflow) => {
    navigate(`/admin-dashboard/view/workflows/${workflowId}/users/${userId}`, {
      state: { userWorkflow, workflowId },
    });
  };

  if (loading) {
    return (
      <div className={styles.viewWorkflowContainer}>
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <div className="loading-message">{t('viewWorkflow.loading')}</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <p className="text-danger fw-bold">{error}</p>
      </div>
    );
  }

  // If no users found
  if (workflowUsers.length === 0) {
    return (
      <div className={styles.viewWorkflowContainer}>
        {/* 4) Localize dashboard title with interpolation */}
        <h1 className={styles.title}>
          {t('viewWorkflow.dashboardTitle', { workflowName: workflowName || 'Workflow' })}
        </h1>
        <p>{t('viewWorkflow.noUsersFound')}</p>
      </div>
    );
  }

  // Filter logic for search
  const filteredUsers = workflowUsers.filter((userWorkflow) => {
    const nameMatch = userWorkflow.user_name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const idMatch = String(userWorkflow.user_id || '').includes(searchQuery);
    const statusMatch = userWorkflow.status
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    return nameMatch || idMatch || statusMatch;
  });

  // We can read the actual workflow name from the first record if we like
  const actualWorkflowName = workflowUsers[0]?.workflow?.name || workflowName || 'Workflow';

  // Example: changes in stats (not sure if you want them)
  const changeInTotalUsers = totalUsers - workflowUsers.length;
  const changeInCompletedUsers =
    completedUsers - workflowUsers.filter((u) => u.num_completed_tasks === u.num_tasks).length;
  const changeInAvgProgress = avgProgress - calculateAverageProgress(workflowUsers);
  const changeInActiveTasks = activeTasks - calculateActiveTasks(workflowUsers);

  return (
    <div className={`${styles.viewWorkflowContainer} ${isArabic ? styles.rtl : ''}`}>
      {/* TOP BAR */}
      <div className={styles.topBar}>
        <h1 className={styles.pageTitle}>
          {t('viewWorkflow.dashboardTitle', { workflowName: actualWorkflowName })}
        </h1>

      </div>

      {/* STATS ROW */}
      <div className={styles.statsRow}>
        <div className={styles.statCard}>
          <FiUsers className={styles.statIcon} />
          <div>
            <h2 className={styles.statNumber}>{totalUsers}</h2>
            <p className={styles.statLabel}>{t('viewWorkflow.totalUsers')}</p>
          </div>
          <span
            className={
              changeInTotalUsers >= 0
                ? styles.statChangePositive
                : styles.statChangeNegative
            }
          >
            {changeInTotalUsers >= 0 ? '+' : ''}
            {changeInTotalUsers}%
          </span>
        </div>

        <div className={styles.statCard}>
          <FiUserCheck className={styles.statIcon} />
          <div>
            <h2 className={styles.statNumber}>{completedUsers}</h2>
            <p className={styles.statLabel}>{t('viewWorkflow.usersCompleted')}</p>
          </div>
          <span
            className={
              changeInCompletedUsers >= 0
                ? styles.statChangePositive
                : styles.statChangeNegative
            }
          >
            {changeInCompletedUsers >= 0 ? '+' : ''}
            {changeInCompletedUsers}%
          </span>
        </div>

        <div className={styles.statCard}>
          <FiTrendingUp className={styles.statIcon} />
          <div>
            <h2 className={styles.statNumber}>{avgProgress}%</h2>
            <p className={styles.statLabel}>{t('viewWorkflow.avgProgress')}</p>
          </div>
          <span
            className={
              changeInAvgProgress >= 0
                ? styles.statChangePositive
                : styles.statChangeNegative
            }
          >
            {changeInAvgProgress >= 0 ? '+' : ''}
            {changeInAvgProgress}%
          </span>
        </div>

        <div className={styles.statCard}>
          <FiClipboard className={styles.statIcon} />
          <div>
            <h2 className={styles.statNumber}>{activeTasks}</h2>
            <p className={styles.statLabel}>{t('viewWorkflow.activeTasks')}</p>
          </div>
          <span
            className={
              changeInActiveTasks >= 0
                ? styles.statChangePositive
                : styles.statChangeNegative
            }
          >
            {changeInActiveTasks >= 0 ? '+' : ''}
            {changeInActiveTasks}%
          </span>
        </div>
      </div>
      <div className={styles.searchContainer}>
        <input
          type="text"
          className={styles.searchInput}
          placeholder={t('viewWorkflow.searchPlaceholder')}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          aria-label="Search users"
        />
      </div>
      {/* USER PROGRESS SECTION */}
      <h2 className={styles.sectionTitle}>{t('viewWorkflow.userProgress')}</h2>
      <div className={styles.cardGrid}>
        {filteredUsers.map((userWorkflow) => {
          const {
            id,
            user_profile_image,
            user_name,
            status,
            last_active,
            num_completed_tasks,
            num_tasks,
          } = userWorkflow;

          const isActive = (status || '').toLowerCase() === 'active';
          const completed = num_completed_tasks || 0;
          const total = num_tasks || 0;
          const progressPercent = total ? Math.round((completed / total) * 100) : 0;

          return (
            <div key={id} className={styles.userCard}>
              <div className={styles.userCardHeader}>
                <div className={styles.userNameStatusImg}>
                  <img
                    src={user_profile_image}
                    alt="Avatar"
                    className={styles.userAvatar}
                  />
                  <div className={styles.userNameStatus}>
                    <h3 className={styles.userName}>{user_name}</h3>
                    <span
                      className={isActive ? styles.statusActive : styles.statusInactive}
                    >
                      {isActive ? t('viewWorkflow.activeStatus') : t('viewWorkflow.inactiveStatus')}
                    </span>
                  </div>
                </div>
                <div className={styles.cardMenu}>
                  <button className={styles.menuButton} aria-label="More actions">⋮</button>
                  <div className={styles.dropdownMenu}>
                    <button>{t('viewWorkflow.viewProfile')}</button>
                    <button>{t('viewWorkflow.viewHistory')}</button>
                  </div>
                </div>
              </div>

              <div className={styles.progressSection}>
                <span className={styles.progressLabel}>{t('viewWorkflow.progressLabel')}</span>
                <span className={styles.progressValue}>{progressPercent}%</span>
              </div>
              <div className={styles.progressBar}>
                <div
                  className={styles.progressFill}
                  style={{ width: `${progressPercent || 1}%` }}
                />
              </div>
              <div className={styles.userMeta}>
                <span className={styles.lastActive}>
                  <FiClock className={styles.metaIcon} />
                  {last_active || t('viewWorkflow.justNow')}
                </span>
                <span className={styles.taskCount}>
                  <FiCheckCircle className={styles.metaIcon} />
                  {completed}/{total}
                </span>
              </div>

              <button
                className={styles.openTasksBtn}
                onClick={() =>
                  handleViewTasks(userWorkflow.workflow.id, userWorkflow.user_id, userWorkflow)
                }
              >
                {t('viewWorkflow.openTasks')}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ViewWorkflow;
