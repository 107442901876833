import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation files
import en from './translations/en.json';
import ar from './translations/ar.json';

const savedLang = localStorage.getItem('appLang') || 'en';


i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: en },
            ar: { translation: ar },
        },
        lng: savedLang,          // default language
        fallbackLng: 'en',  // fallback if no translation found
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
