import React from 'react';
import styles from '../../styles/components/WorkflowDetails/WorkflowTasks.module.css';

const ProgressBar = ({ progressValue }) => {
    let statusLabel = '';
    let progressStyle = '';
    let progressWidth = '0%';

    if (progressValue === 0) {
        statusLabel = 'Not Started';
        progressStyle = styles.notStarted;
        progressWidth = '100%'; 
    } else if (progressValue > 0 && progressValue < 100) {
        statusLabel = 'In Progress';
        progressStyle = styles.inProgress;
        progressWidth = `${progressValue}%`;
    } else if (progressValue === 100) {
        statusLabel = 'Completed';
        progressStyle = styles.completed;
        progressWidth = '100%';
    }

    return (
        <div className={styles.progressContainer} aria-label={`Task is ${statusLabel}`}>
            <div
                className={`${styles.progressBar} ${progressStyle}`}
                style={{ width: progressWidth }}
                role="progressbar"
                aria-valuenow={progressValue}
                aria-valuemin="0"
                aria-valuemax="100"
            >
                <span className={styles.progressText}>
                    {progressValue > 0 && progressValue < 100 ? `${progressValue}%` : statusLabel}
                </span>
            </div>
        </div>
    );
};

export default ProgressBar;
