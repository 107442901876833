import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import config from '../../config';
import styles from '../../styles/components/WorkflowDetails/FormItem.module.css';
import SignatureInput from '../FieldTypesInput/SignatureInput';

import { 
    FaInfoCircle, 
    FaCheckCircle, 
    FaExclamationCircle, 
    FaQuestionCircle 
} from 'react-icons/fa';
import * as ReactTooltip from 'react-tooltip';

export const FormItem = ({ item, userTask, onChange, answer, workflowId, onSavingStateChange }) => {
  const field = userTask.user_task_fields.find(f => f.fieldName === item.fieldName);
  const fieldValue = field?.fieldValue || '';
  const [currentValue, setCurrentValue] = useState(answer || fieldValue || '');
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  const saveData = useCallback((fieldId, value) => {
    const authToken = localStorage.getItem('authToken');
    setIsSaving(true);
    onSavingStateChange(true);
    axios.patch(`${config.apiUrl}/user-task-fields/${fieldId}/`, {
      fieldValue: value
    }, {
      headers: { 'Authorization': `Token ${authToken}` }
    })
      .then(() => {
        setIsSaving(false);
        setError(null);
        setSuccess(true);
        onSavingStateChange(false);
        setTimeout(() => setSuccess(false), 3000);
      })
      .catch(error => {
        console.error('Save failed', error);
        setIsSaving(false);
        setError('Failed to save changes. Please try again.');
        onSavingStateChange(false);
      });
  }, [workflowId, onSavingStateChange]);

  const debouncedSave = useCallback(debounce(saveData, 800), [saveData]);

  const handleChange = (newValue) => {
    if (!item || !item.fieldName) {
      console.error('Item or fieldName is missing.');
      return;
    }

    const fieldId = field?.id;
    if (!fieldId) {
      console.error('Field ID not found.');
      return;
    }

    setCurrentValue(newValue);
    onChange(newValue, item.fieldName);
    debouncedSave(fieldId, newValue);
  };

  const fieldType = item.fieldTypeID;
  const fieldLabel = item.fieldName || "Field";
  const hintText = item.hint || 'thie is a hint text.';
  const isInformation = fieldType === 'information';
  const configData = item.config || {};
  const isCheckbox = fieldType === 'checkbox';

  const {
    required = false,
    help_text = '',
    placeholder = '',
    max_length,
    min_value,
    max_value,
    step,
    default_value,
    default_values,
    choices,
    allowed_file_types,
    max_file_size,
    min_date,
    max_date,
    rows,
    tooltip
  } = configData;

  useEffect(() => {
    if ((currentValue === '' || currentValue == null) && default_value !== undefined) {
      handleChange(default_value);
    }
  }, [default_value]);

  useEffect(() => {
    if ((currentValue === '' || currentValue == null) && default_values && Array.isArray(default_values)) {
      handleChange(default_values);
    }
  }, [default_values]);

  const renderSpinner = (small = false) => {
    return (
      <div className={small ? styles.customSpinnerSmall : styles.customSpinner}></div>
    );
  };

  const handleMultiSelectToggle = (choice, selectedValues) => {
    let newValues = [...selectedValues];
    if (newValues.includes(choice)) {
      newValues = newValues.filter(v => v !== choice);
    } else {
      newValues.push(choice);
    }
    handleChange(newValues);
  };

  const renderMultiSelectButtons = () => {
    // multi_select_buttons logic
    const selectedValues = Array.isArray(currentValue) ? currentValue : (currentValue ? [currentValue] : []);

    return (
      <div className={styles.multiSelectGroup}>
        {choices && choices.map((choice, i) => {
          const isSelected = selectedValues.includes(choice);
          return (
            <button
              key={i}
              type="button"
              className={`${styles.multiSelectOption} ${isSelected ? styles.multiSelectOptionSelected : ''}`}
              onClick={() => handleMultiSelectToggle(choice, selectedValues)}
              aria-pressed={isSelected}
            >
              {choice}
            </button>
          );
        })}
        {tooltip && (
          <ReactTooltip id={`${item.id}-tooltip`} place="top" effect="solid">
            <FaQuestionCircle className={styles.tooltipIcon} /> {tooltip}
          </ReactTooltip>
        )}
        {isSaving && renderSpinner(true)}
        {success && <FaCheckCircle className={styles.successIconSmall} />}
        {error && <FaExclamationCircle className={styles.errorIconSmall} />}
      </div>
    );
  };

  const renderMultiSelectCheckboxes = () => {
    // multi_select_checkboxes logic
    const selectedValues = Array.isArray(currentValue) ? currentValue : (currentValue ? [currentValue] : []);

    return (
      <div className={styles.multiCheckboxGroup}>
        {choices && choices.map((choice, i) => {
          const isChecked = selectedValues.includes(choice);
          return (
            <label key={i} className={styles.multiCheckboxLabel}>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={() => handleMultiSelectToggle(choice, selectedValues)}
                className={styles.multiCheckboxInput}
              />
              <span className={styles.multiCheckboxText}>{choice}</span>
            </label>
          );
        })}
        {tooltip && (
          <ReactTooltip id={`${item.id}-tooltip`} place="top" effect="solid">
            <FaQuestionCircle className={styles.tooltipIcon} /> {tooltip}
          </ReactTooltip>
        )}
        {isSaving && renderSpinner(true)}
        {success && <FaCheckCircle className={styles.successIconSmall} />}
        {error && <FaExclamationCircle className={styles.errorIconSmall} />}
      </div>
    );
  };

  const renderInputField = () => {
    if (isInformation) {
      return (
        <div className={styles.informationField}>
          <FaInfoCircle className={styles.infoIcon} />
          <span>{fieldLabel}</span>
        </div>
      );
    }

    switch (fieldType) {
      case 'text':
      case 'password':
      case 'number':
        return (
          <div className={styles.inputWrapper}>
            <input
              type={fieldType}
              required={required}
              placeholder={placeholder}
              maxLength={max_length}
              value={currentValue}
              onChange={(e) => handleChange(e.target.value)}
              className={`${styles.formInput} ${error ? styles.inputError : ''}`}
              aria-invalid={error ? "true" : "false"}
              data-tip={tooltip || ''}
              data-for={`${item.id}-tooltip`}
            />
            {tooltip && (
              <ReactTooltip id={`${item.id}-tooltip`} place="top" effect="solid">
                <FaQuestionCircle className={styles.tooltipIcon} /> {tooltip}
              </ReactTooltip>
            )}
            {isSaving && renderSpinner()}
            {success && <FaCheckCircle className={styles.successIcon} />}
            {error && <FaExclamationCircle className={styles.errorIcon} />}
          </div>
        );

      case 'checkbox':
        return (
          <div className={styles.checkboxContainer}>
            <input
              type="checkbox"
              required={required}
              checked={!!currentValue}
              onChange={(e) => handleChange(e.target.checked)}
              className={styles.checkbox}
              aria-checked={!!currentValue}
            />
            <label className={styles.formLabel}>
              {fieldLabel}
              {required && <span className={styles.required}> *</span>}
            </label>
            {isSaving && renderSpinner(true)}
            {success && <FaCheckCircle className={styles.successIconSmall} />}
            {error && <FaExclamationCircle className={styles.errorIconSmall} />}
          </div>
        );

      case 'radiobutton':
        return (
          <div className={styles.radioGroup}>
            {choices && choices.map((choice, i) => (
              <label key={i} className={styles.radioLabel}>
                <input
                  type="radio"
                  name={item.id}
                  value={choice}
                  required={required}
                  checked={currentValue === choice}
                  onChange={(e) => handleChange(e.target.value)}
                  className={styles.radioInput}
                />
                <span className={styles.radioText}>{choice}</span>
              </label>
            ))}
            {isSaving && renderSpinner(true)}
            {success && <FaCheckCircle className={styles.successIconSmall} />}
            {error && <FaExclamationCircle className={styles.errorIconSmall} />}
          </div>
        );

      case 'multi_select_buttons':
        return renderMultiSelectButtons();

      case 'multi_select_checkboxes':
        return renderMultiSelectCheckboxes();

      case 'date':
        return (
          <div className={styles.inputWrapper}>
            <input
              type="date"
              required={required}
              min={min_date}
              max={max_date}
              value={currentValue}
              onChange={(e) => handleChange(e.target.value)}
              className={`${styles.formInput} ${error ? styles.inputError : ''}`}
              aria-invalid={error ? "true" : "false"}
              data-tip={tooltip || ''}
              data-for={`${item.id}-tooltip`}
            />
            {tooltip && (
              <ReactTooltip id={`${item.id}-tooltip`} place="top" effect="solid">
                <FaQuestionCircle className={styles.tooltipIcon} /> {tooltip}
              </ReactTooltip>
            )}
            {isSaving && renderSpinner()}
            {success && <FaCheckCircle className={styles.successIcon} />}
            {error && <FaExclamationCircle className={styles.errorIcon} />}
          </div>
        );

      case 'datetime':
        return (
          <div className={styles.inputWrapper}>
            <input
              type="datetime_local"
              required={required}
              min={min_date}
              max={max_date}
              value={currentValue}
              onChange={(e) => handleChange(e.target.value)}
              className={`${styles.formInput} ${error ? styles.inputError : ''}`}
              aria-invalid={error ? "true" : "false"}
              data-tip={tooltip || ''}
              data-for={`${item.id}-tooltip`}
            />
            {tooltip && (
              <ReactTooltip id={`${item.id}-tooltip`} place="top" effect="solid">
                <FaQuestionCircle className={styles.tooltipIcon} /> {tooltip}
              </ReactTooltip>
            )}
            {isSaving && renderSpinner()}
            {success && <FaCheckCircle className={styles.successIcon} />}
            {error && <FaExclamationCircle className={styles.errorIcon} />}
          </div>
        );

      case 'textarea':
        return (
          <div className={styles.textareaWrapper}>
            <textarea
              required={required}
              placeholder={placeholder}
              maxLength={max_length}
              rows={rows || 5}
              value={currentValue}
              onChange={(e) => handleChange(e.target.value)}
              className={`${styles.formTextarea} ${error ? styles.inputError : ''}`}
              aria-invalid={error ? "true" : "false"}
              data-tip={tooltip || ''}
              data-for={`${item.id}-tooltip`}
            />
            {tooltip && (
              <ReactTooltip id={`${item.id}-tooltip`} place="top" effect="solid">
                <FaQuestionCircle className={styles.tooltipIcon} /> {tooltip}
              </ReactTooltip>
            )}
            {isSaving && renderSpinner()}
            {success && <FaCheckCircle className={styles.successIcon} />}
            {error && <FaExclamationCircle className={styles.errorIcon} />}
          </div>
        );

      case 'dropdown':
        return (
          <div className={styles.inputWrapper}>
            <select
              required={required}
              value={currentValue}
              onChange={(e) => handleChange(e.target.value)}
              className={`${styles.formSelect} ${error ? styles.inputError : ''}`}
              aria-invalid={error ? "true" : "false"}
              data-tip={tooltip || ''}
              data-for={`${item.id}-tooltip`}
            >
              <option value="" disabled>Select an option</option>
              {choices && choices.map((choice, i) => (
                <option key={i} value={choice}>{choice}</option>
              ))}
            </select>
            {tooltip && (
              <ReactTooltip id={`${item.id}-tooltip`} place="top" effect="solid">
                <FaQuestionCircle className={styles.tooltipIcon} /> {tooltip}
              </ReactTooltip>
            )}
            {isSaving && renderSpinner()}
            {success && <FaCheckCircle className={styles.successIcon} />}
            {error && <FaExclamationCircle className={styles.errorIcon} />}
          </div>
        );

      case 'file_upload':
        return (
          <div className={styles.fileUploadWrapper}>
            <label htmlFor={item.id} className={styles.fileUploadLabel}>
              {currentValue && currentValue.name ? currentValue.name : 'Choose a file'}
            </label>
            <input
              type="file"
              required={required}
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  handleChange(file);
                }
              }}
              accept={allowed_file_types && allowed_file_types.map(type => `.${type}`).join(',')}
              className={styles.fileInput}
              aria-describedby={`${item.id}-file-help`}
            />
            {isSaving && renderSpinner(true)}
            {success && <FaCheckCircle className={styles.successIconSmall} />}
            {error && <FaExclamationCircle className={styles.errorIconSmall} />}
            {max_file_size && (
              <small id={`${item.id}-file-help`} className={styles.fileHelp}>
                Maximum file size: {max_file_size}MB
              </small>
            )}
          </div>
        );

      case 'slider':
        return (
          <div className={styles.rangeContainer}>
            <input
              type="range"
              required={required}
              min={min_value}
              max={max_value}
              step={step || 1}
              value={currentValue}
              onChange={(e) => handleChange(e.target.value)}
              className={styles.rangeInput}
              aria-valuemin={min_value}
              aria-valuemax={max_value}
              aria-valuenow={currentValue}
              data-tip={tooltip || ''}
              data-for={`${item.id}-tooltip`}
            />
            {tooltip && (
              <ReactTooltip id={`${item.id}-tooltip`} place="top" effect="solid">
                <FaQuestionCircle className={styles.tooltipIcon} /> {tooltip}
              </ReactTooltip>
            )}
            <div className={styles.rangeValue}>Selected Value: {currentValue}</div>
            {isSaving && renderSpinner(true)}
            {success && <FaCheckCircle className={styles.successIconSmall} />}
            {error && <FaExclamationCircle className={styles.errorIconSmall} />}
          </div>
        );

      case 'signature':
        return (
          <SignatureInput
            required={required}
            helpText={help_text}
            placeholder={placeholder}
            currentValue={currentValue}
            onChange={(val) => handleChange(val)}
            isSaving={isSaving}
            error={error}
            success={success}
            tooltip={tooltip}
            itemId={item.id}
          />
        );

      default:
        return (
          <p className={styles.unsupportedField}>
            Unsupported field type: {fieldType}
          </p>
        );
    }
  };

  return (
    <div className={styles.formItemContainer}>
      {(!isInformation && !isCheckbox )&& (
        <div className={styles.labelRow}>
          <label className={styles.formLabel}>
            {fieldLabel}
            {required && <span className={styles.required}> *</span>}
          </label>
          {help_text && (
            <div className={styles.helpBox}>
              <FaInfoCircle className={styles.helpIcon} />
              <span>{help_text}</span>
            </div>
          )}
        </div>
      )}

      {renderInputField()}

      {hintText && (
        <div className={styles.fieldHint}>
          {hintText}
        </div>
      )}
    </div>
  );
};

export default FormItem;