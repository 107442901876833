import React, { useState, useEffect } from 'react';
import styles from '../../styles/components/FieldTypes/FieldTypes.module.css';
import { FaCloudUploadAlt } from 'react-icons/fa'; // Import an upload icon
import { calculateLabelWidth } from './FieldTypesUtils';
// List of configuration keys for FileUpload field

export const FileUploadConfig = [
  "fieldLabel",
  "required",
  "help_text",
  "max_file_size",
  "allowed_file_types",
];

function FileUpload({ field, onConfigChange, isSelected }) {
  const [label, setLabel] = useState(field.config.fieldLabel || "");
  const [helpText, setHelpText] = useState(field.config.help_text || "");
  const isRequired = field.config.required;

  // Drag-and-drop state
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    setLabel(field.config.fieldLabel || "");
    setHelpText(field.config.help_text || "");

    const labelWidth = calculateLabelWidth(field.config.fieldLabel);
    document.documentElement.style.setProperty(
      `--label-length-${field.id}`,
      labelWidth / 8
    );

  }, [field.config]);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  return (
    <div className={styles.fileUpload}>
      {/* Label Section */}
      <div className={styles.labelWrapper}>
        <div className={styles.labelContainer}>
          <textarea
            className={styles.fieldLabel}
            value={label}
            placeholder={isSelected ? "Type a label" : ""}
            onChange={(e) => onConfigChange({ ...field.config, fieldLabel: e.target.value })}
            rows="1"
          />
          {isRequired && (
            <span
              style={{
                left: `max(min(calc(10px + 1ch * var(--label-length-${field.id})), calc(100% - 40px)), calc(14%))`,
                top: '5px',
                position: 'absolute',
                color: 'red',
              }}
            >
              *
            </span>
          )}
        </div>
      </div>

      {/* Drag and Drop Zone */}
      <div
        className={`${styles.dragDropZone} ${isDragging ? styles.dragOver : ''}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <FaCloudUploadAlt className={styles.uploadIcon} />
        <p className={styles.dragDropText}>
          <strong>Drag and drop your files here</strong>
          <br />
          or <span className={styles.browseLink}>click to browse</span>
        </p>
        <p className={styles.allowedFileTypes}>
          Allowed file types: {field.config.allowed_file_types || "All file types"}
        </p>
        <input type="file" className={styles.hiddenInput} disabled />
      </div>

      {/* Help Text */}
      <textarea
        className={styles.helpText}
        value={helpText}
        placeholder={isSelected ? "Type a help text" : ""}
        onChange={(e) => onConfigChange({ ...field.config, help_text: e.target.value })}
        rows="1"
      />
    </div>
  );
}

export default FileUpload;
