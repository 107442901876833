// src/components/WorkflowBuilder/AnimatedEdge.jsx
import React from "react";
import { getBezierPath } from "reactflow";
import styles from "../../styles/components/WorkflowBuilder/AnimatedEdge.module.css";

function AnimatedEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  markerEnd,
}) {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
  });

  return (
    <path
      id={id}
      d={edgePath}
      className={styles.animatedEdge}
      style={style}
      markerEnd={markerEnd}
    />
  );
}

export default AnimatedEdge;
