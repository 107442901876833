export const calculateLabelWidth = (label) => {
    // calculate the width of the label
    const text = document.createElement('span');
    text.style.font = 'inherit';
    text.style.fontSize = '1.05em'; 
    text.style.fontWeight = 600;
    text.style.visibility = 'hidden';
    text.style.whiteSpace = 'pre';
    text.style.position = 'absolute';
    text.style.top = '0';
    text.style.left = '0';
    text.style.display = 'inline';
  
    text.textContent = label;
    document.body.appendChild(text);
    const labelWidth = text.getBoundingClientRect().width;
    document.body.removeChild(text);
    return labelWidth;
  }
  
  