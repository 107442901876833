// src/components/WorkflowBuilder/AddDependencyModal.jsx
import React, { useState } from "react";
import { toast } from "react-toastify";
import styles from "../../styles/components/WorkflowBuilder/AdminDependenciesView.module.css";

export default function AddDependencyModal({ tasks, onClose, onAdd }) {
  const [fromId, setFromId] = useState("");
  const [toId, setToId] = useState("");
  const [depType, setDepType] = useState("must_submit_before_view");

  const handleSubmit = () => {
    if (!fromId || !toId) {
      toast.warn("Please select both tasks");
      return;
    }
    onAdd(fromId, toId, depType);
    onClose();
  };

  return (
    <div className={styles.addModalOverlay} onClick={onClose}>
      <div className={styles.addModalContent} onClick={(e) => e.stopPropagation()}>
        {/* A big color banner */}
        <div className={styles.modalBanner}>
          <h2>Add Dependency</h2>
        </div>

        <div className={styles.modalBody}>
          <div className={styles.fieldRow}>
            <label>From Task:</label>
            <select value={fromId} onChange={(e) => setFromId(e.target.value)}>
              <option value="">-- select --</option>
              {tasks.map((t) => (
                <option key={t.id} value={t.id}>
                  {t.name}
                </option>
              ))}
            </select>
          </div>

          <div className={styles.fieldRow}>
            <label>To Task:</label>
            <select value={toId} onChange={(e) => setToId(e.target.value)}>
              <option value="">-- select --</option>
              {tasks.map((t) => (
                <option key={t.id} value={t.id}>
                  {t.name}
                </option>
              ))}
            </select>
          </div>

          <div className={styles.fieldRow}>
            <label>Type:</label>
            <select value={depType} onChange={(e) => setDepType(e.target.value)}>
              <option value="must_submit_before_view">Must Submit Before View</option>
              <option value="can_view_only_before_submit">View-Only Before Submit</option>
              <option value="no_restriction">No Restriction</option>
            </select>
          </div>
        </div>

        <div className={styles.modalActions}>
          <button className={styles.flatBtn} onClick={onClose}>
            Cancel
          </button>
          <button className={styles.addBtn} onClick={handleSubmit}>
            Add
          </button>
        </div>
      </div>
    </div>
  );
}
