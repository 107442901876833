import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import styles from '../../styles/pages/Notifications/Notifications.module.css';
import config from '../../config';
import axios from 'axios';
import { FiClock, FiRefreshCw, FiBell, FiVolume2, FiArrowLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

// 1) Import the translation hook
import { useTranslation } from 'react-i18next';

const NotificationSettings = () => {
    const { t } = useTranslation(); // 2) get the translation function

    const navigate = useNavigate();
    const [settings, setSettings] = useState({
        notify_deadlines: true,
        notify_status_changes: true,
        notify_workflow_updates: true,
        enable_sounds: false,
        preferred_method: 'both',
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchSettings();
    }, []);

    const fetchSettings = async () => {
        try {
            setLoading(true);
            const authToken = localStorage.getItem('authToken');
            const response = await axios.get(`${config.apiUrl}/notifications/settings/`, {
                headers: {
                    Authorization: `Token ${authToken}`,
                },
            });
            if (response.status !== 200) {
                throw new Error(t('notificationSettings.fetchFail'));
            }
            setSettings(response.data);
        } catch (error) {
            toast.error(error.message || t('notificationSettings.fetchFail'));
        } finally {
            setLoading(false);
        }
    };

    const handleToggle = (fieldName, value) => {
        setSettings((prev) => ({ ...prev, [fieldName]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const authToken = localStorage.getItem('authToken');
        try {
            const response = await fetch(`${config.apiUrl}/notifications/settings/`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${authToken}`,
                },
                body: JSON.stringify(settings),
            });
            if (!response.ok) {
                throw new Error(t('notificationSettings.saveFail'));
            }
            toast.success(t('notificationSettings.saveSuccess'));
        } catch (error) {
            toast.error(error.message || t('notificationSettings.saveFail'));
        }
    };

    const handleBack = () => {
        navigate('/notifications');
    };

    return loading ? (
        <div className={styles.notifWrapper}>
            {/* Top Bar */}
            <div className={styles.topBar}>
                <button className={styles.backButton} onClick={handleBack}>
                    <FiArrowLeft className={styles.backIcon} />
                </button>
                <div className={styles.titleSection}>
                    <div className={styles.bellContainer}>
                        <FiBell className={styles.bellIcon} />
                    </div>
                    <h2 className={styles.pageTitle}>
                        {t('notificationSettings.pageTitle')}
                    </h2>
                </div>
            </div>
            {/* You could also show a spinner or "Loading settings..." */}
        </div>
    ) : (
        <div className={styles.notifWrapper}>
            {/* Top Bar */}
            <div className={styles.topBar}>
                <button className={styles.backButton} onClick={handleBack}>
                    <FiArrowLeft className={styles.backIcon} />
                </button>
                <div className={styles.titleSection}>
                    <div className={styles.bellContainer}>
                        <FiBell className={styles.bellIcon} />
                    </div>
                    <h2 className={styles.pageTitle}>
                        {t('notificationSettings.pageTitle')}
                    </h2>
                </div>
            </div>

            {/* Setting 1: Deadlines */}
            <div
                className={styles.settingCard}
                onClick={() => handleToggle('notify_deadlines', !settings.notify_deadlines)}
            >
                <div className={styles.leftSection}>
                    <FiClock className={styles.settingIcon} />
                    <div>
                        <h4 className={styles.settingName}>
                            {t('notificationSettings.notifyDeadlinesTitle')}
                        </h4>
                        <p className={styles.settingDescription}>
                            {t('notificationSettings.notifyDeadlinesDesc')}
                        </p>
                    </div>
                </div>
                <ToggleSwitch
                    checked={settings.notify_deadlines}
                    onChange={(newVal) => handleToggle('notify_deadlines', newVal)}
                />
            </div>

            {/* Setting 2: Workflow Status Changes */}
            <div
                className={styles.settingCard}
                onClick={() =>
                    handleToggle('notify_status_changes', !settings.notify_status_changes)
                }
            >
                <div className={styles.leftSection}>
                    <FiRefreshCw className={styles.settingIcon} />
                    <div>
                        <h4 className={styles.settingName}>
                            {t('notificationSettings.notifyStatusChangesTitle')}
                        </h4>
                        <p className={styles.settingDescription}>
                            {t('notificationSettings.notifyStatusChangesDesc')}
                        </p>
                    </div>
                </div>
                <ToggleSwitch
                    checked={settings.notify_status_changes}
                    onChange={(newVal) => handleToggle('notify_status_changes', newVal)}
                />
            </div>

            {/* Setting 3: Workflow Updates */}
            <div
                className={styles.settingCard}
                onClick={() =>
                    handleToggle('notify_workflow_updates', !settings.notify_workflow_updates)
                }
            >
                <div className={styles.leftSection}>
                    <FiBell className={styles.settingIcon} />
                    <div>
                        <h4 className={styles.settingName}>
                            {t('notificationSettings.notifyWorkflowUpdatesTitle')}
                        </h4>
                        <p className={styles.settingDescription}>
                            {t('notificationSettings.notifyWorkflowUpdatesDesc')}
                        </p>
                    </div>
                </div>
                <ToggleSwitch
                    checked={settings.notify_workflow_updates}
                    onChange={(newVal) => handleToggle('notify_workflow_updates', newVal)}
                />
            </div>

            {/* Setting 4: Enable Sounds */}
            <div
                className={styles.settingCard}
                onClick={() => handleToggle('enable_sounds', !settings.enable_sounds)}
            >
                <div className={styles.leftSection}>
                    <FiVolume2 className={styles.settingIcon} />
                    <div>
                        <h4 className={styles.settingName}>
                            {t('notificationSettings.enableSoundsTitle')}
                        </h4>
                        <p className={styles.settingDescription}>
                            {t('notificationSettings.enableSoundsDesc')}
                        </p>
                    </div>
                </div>
                <ToggleSwitch
                    checked={settings.enable_sounds}
                    onChange={(newVal) => handleToggle('enable_sounds', newVal)}
                />
            </div>

            <hr className={styles.divider} />

            {/* Notification Method (select) */}
            <div className={styles.fieldGroup}>
                <label className={styles.fieldLabel}>
                    {t('notificationSettings.notificationMethod')}
                </label>
                <select
                    className={styles.methodSelect}
                    value={settings.preferred_method}
                    onChange={(e) =>
                        handleToggle('preferred_method', e.target.value.toLowerCase())
                    }
                >
                    <option value="email">{t('notificationSettings.emailOpt')}</option>
                    <option value="dashboard">{t('notificationSettings.dashboardOpt')}</option>
                    <option value="both">{t('notificationSettings.bothOpt')}</option>
                </select>
            </div>

            <div className={styles.saveBtnContainer}>
                <button className={styles.saveButton} onClick={handleSubmit}>
                    {t('notificationSettings.saveSettings')}
                </button>
            </div>
        </div>
    );
};

const ToggleSwitch = ({ checked, onChange }) => {
    const handleClick = (e) => {
        e.stopPropagation();
        onChange(!checked);
    };

    return (
        <div
            className={`${styles.toggleSwitch} ${checked ? styles.toggleOn : ''}`}
            onClick={handleClick}
        >
            <div className={styles.toggleKnob} />
        </div>
    );
};

export default NotificationSettings;
