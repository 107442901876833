import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import {
    FiEye,
    FiBell,
    FiFilter,
    FiCheckSquare,
    FiSettings,
    FiSquare,
} from 'react-icons/fi';
import { toast } from 'react-toastify';
import config from '../../config';
import axios from 'axios';
import styles from '../../styles/pages/Notifications/Notifications.module.css';

// 1) Import translation hook
import { useTranslation } from 'react-i18next';

const Notifications = () => {
    const { t } = useTranslation(); // 2) get the translation function

    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('All');
    const [selectedIds, setSelectedIds] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        fetchNotifications();
    }, []);

    const fetchNotifications = async () => {
        try {
            setLoading(true);
            const authToken = localStorage.getItem('authToken');
            const response = await axios.get(`${config.apiUrl}/notifications/`, {
                headers: {
                    Authorization: `Token ${authToken}`,
                },
            });

            if (!response.status === 'ok') {
                throw new Error(t('notifications.errorFetch'));
            }

            setNotifications(response.data);
        } catch (error) {
            toast.error(error.message || t('notifications.errorFetch'));
        } finally {
            setLoading(false);
        }
    };

    // Single "Mark as Read"
    const markAsRead = async (id) => {
        try {
            const authToken = localStorage.getItem('authToken');
            const response = await fetch(
                `${config.apiUrl}/notifications/${id}/mark-as-read/`,
                {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${authToken}`,
                    },
                    body: JSON.stringify({}),
                }
            );
            if (!response.ok) {
                throw new Error(t('notifications.errorMarkRead'));
            }
            setNotifications((prev) =>
                prev.map((n) => (n.id === id ? { ...n, is_read: true } : n))
            );
            toast.success(t('notifications.notificationMarked'));
        } catch (error) {
            toast.error(error.message || t('notifications.errorMarkRead'));
        }
    };

    // Batch "Mark as Read"
    const markSelectedAsRead = async () => {
        try {
            const authToken = localStorage.getItem('authToken');
            await Promise.all(
                selectedIds.map((id) =>
                    fetch(`${config.apiUrl}/notifications/${id}/mark-as-read/`, {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Token ${authToken}`,
                        },
                        body: JSON.stringify({}),
                    })
                )
            );

            setNotifications((prev) =>
                prev.map((n) =>
                    selectedIds.includes(n.id) ? { ...n, is_read: true } : n
                )
            );
            toast.success(t('notifications.selectedMarked'));
            setSelectedIds([]);
        } catch (error) {
            toast.error(error.message || t('notifications.errorMarkMulti'));
        }
    };

    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    };

    // Toggling a notification's checkbox (only for unread)
    const handleCheckboxClick = (notifId, isRead) => {
        if (isRead) return;
        setSelectedIds((prev) => {
            if (prev.includes(notifId)) {
                return prev.filter((id) => id !== notifId);
            } else {
                return [...prev, notifId];
            }
        });
    };

    const unreadCount = notifications.filter((n) => !n.is_read).length;

    let filteredNotifications = notifications;
    if (activeTab === 'Unread') {
        filteredNotifications = notifications.filter((n) => !n.is_read);
    } else if (activeTab === 'Workflows') {
        filteredNotifications = notifications.filter((n) => n.workflow);
    }

    return (
        <div className={styles.notifWrapper}>
            {/* Header row */}
            <div className={styles.notifHeader}>
                <div className={styles.titleSection}>
                    <div className={styles.bellContainer}>
                        <FiBell className={styles.bellIcon} />
                        {unreadCount > 0 && <span className={styles.badge}>{unreadCount}</span>}
                    </div>
                    <h2 className={styles.pageTitle}>{t('notifications.pageTitle')}</h2>
                </div>
                <div className={styles.iconBar}>
                    <NavLink to="/notifications/settings">
                        <FiSettings className={styles.headerIcon} title={t('notificationSettings.pageTitle')} />
                    </NavLink>
                </div>
            </div>

            {/* Tabs */}
            <div className={styles.tabRow}>
                <button
                    className={`${styles.tabButton} ${activeTab === 'All' ? styles.activeTab : ''}`}
                    onClick={() => handleTabChange('All')}
                >
                    {t('notifications.allTab')}
                </button>
                <button
                    className={`${styles.tabButton} ${activeTab === 'Unread' ? styles.activeTab : ''}`}
                    onClick={() => handleTabChange('Unread')}
                >
                    {t('notifications.unreadTab')}
                </button>
                <button
                    className={`${styles.tabButton} ${activeTab === 'Workflows' ? styles.activeTab : ''}`}
                    onClick={() => handleTabChange('Workflows')}
                >
                    {t('notifications.workflowsTab')}
                </button>
            </div>

            {/* Multi-Select Bar */}
            {selectedIds.length > 0 && (
                <div className={styles.multiSelectBar}>
                    <span>
                        {t('notifications.selectedCount', { count: selectedIds.length })}
                    </span>
                    <button className={styles.markSelectedBtn} onClick={markSelectedAsRead}>
                        {t('notifications.markSelectedAsRead')}
                    </button>
                </div>
            )}

            {/* Notification list */}
            <div className={styles.notifList}>
                {loading ? (
                    <div className={styles.emptyState}>{t('notifications.loading')}</div>
                ) : filteredNotifications.length === 0 ? (
                    <div className={styles.emptyState}>
                        {t('notifications.noNotifications')}
                    </div>
                ) : (
                    filteredNotifications.map((notif) => (
                        <NotificationCard
                            key={notif.id}
                            notification={notif}
                            onMarkAsRead={() => markAsRead(notif.id)}
                            isSelected={selectedIds.includes(notif.id)}
                            onCheckboxClick={handleCheckboxClick}
                        />
                    ))
                )}
            </div>
        </div>
    );
};

const NotificationCard = ({
    notification,
    onMarkAsRead,
    isSelected,
    onCheckboxClick,
}) => {
    const { id, created_at, message, is_read, notification_type } = notification;
    // If your notifications have something like "workflow_update", convert `_` to space:
    const label = notification_type.replace('_', ' ');

    // You might define a colorMap for each type
    const colorMap = {
        workflow_update: '#4caf50',
        deadline_approach: '#ff9800',
        other: '#009688',
    };

    const { t } = useTranslation(); // For card text

    return (
        <div className={`${styles.card} ${is_read ? styles.read : styles.unread}`}>
            <div className={styles.cardHeader}>
                <div
                    className={`${styles.checkBox} ${is_read ? styles.disabledCheckBox : ''}`}
                    onClick={() => onCheckboxClick(id, is_read)}
                    title={is_read ? t('notifications.alreadyRead') : t('notifications.selectNotification')}
                    style={{ cursor: is_read ? 'not-allowed' : 'pointer' }}
                >
                    {is_read ? (
                        <FiCheckSquare className={styles.checkIcon} />
                    ) : isSelected ? (
                        <FiCheckSquare className={styles.checkIcon} />
                    ) : (
                        <FiSquare className={styles.checkIcon} />
                    )}
                </div>

                <span
                    className={styles.labelChip}
                    style={{ backgroundColor: colorMap[notification_type] || '#9c27b0' }}
                >
                    {label}
                </span>
                <span className={styles.timeStamp}>{created_at}</span>
            </div>

            <div className={styles.cardBody}>
                <p>{message}</p>
            </div>

            {!is_read && (
                <div className={styles.cardFooter}>
                    <button className={styles.markAsReadBtn} onClick={onMarkAsRead}>
                        <FiEye className={styles.eyeIcon} />
                        {t('notifications.markAsRead')}
                    </button>
                </div>
            )}
        </div>
    );
};

export default Notifications;
