import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from '../../styles/components/FieldTypes/FieldTypes.module.css';

// Configuration keys for CheckboxField
export const CheckboxFieldConfig = [
  "fieldLabel",
  "required",
  "help_text",
  "default_value_checkbox",
];

function CheckboxField({ field, onConfigChange, isSelected }) {
  const [label, setLabel] = useState(field.config.fieldLabel || "");
  const [isChecked, setIsChecked] = useState(field.config.default_value_checkbox || false);
  const [helpText, setHelpText] = useState(field.config.help_text || "");

  // Handle rich text label change
  const handleLabelChange = (value) => {
    const updatedConfig = { ...field.config, fieldLabel: value };
    setLabel(value);
    onConfigChange(updatedConfig);
  };

  // Handle help text change
  const handleHelpTextChange = (e) => {
    const updatedConfig = { ...field.config, help_text: e.target.value };
    setHelpText(e.target.value);
    onConfigChange(updatedConfig);
  };

  // Handle default value change
  const handleCheckboxChange = (e) => {
    const updatedConfig = { ...field.config, default_value_checkbox: e.target.checked };
    setIsChecked(e.target.checked);
    onConfigChange(updatedConfig);
  };

  useEffect(() => {
    setLabel(field.config.fieldLabel || "");
    setIsChecked(field.config.default_value_checkbox || false);
    setHelpText(field.config.help_text || "");
  }, [field.config]);

  return (
    <div className={styles.checkboxField}>
      {/* Checkbox and Preview/Editor */}
      <div className={styles.checkboxContainer}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        {isSelected ? (
          // Show editor when selected
          <ReactQuill
            className={styles.customRichTextEditor}
            theme="snow"
            value={label}
            onChange={handleLabelChange}
            placeholder="Enter checkbox label (you can add links or formatted text)"
            modules={{
              toolbar: [
                ['bold', 'italic', 'underline', 'strike'], // Formatting options
                ['link'], // Link option
                [{ list: 'ordered' }, { list: 'bullet' }], // Lists
                [{ align: [] }], // Alignment
              ],
            }}
          />
        ) : (
          // Show preview when not selected
          <div
            className={styles.labelPreview}
          >
            <div
              dangerouslySetInnerHTML={{ __html: label }} 
            />

            {field.config.required && (
              <span className={styles.requiredStarCheckBox}>*</span>
            )}

          </div>
          

        )}
      </div>

      {/* Editable Help Text */}
      {(isSelected || helpText.length > 0) && (
        <textarea
          className={styles.helpText}
          value={helpText}
          placeholder="Enter help text"
          onChange={handleHelpTextChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.currentTarget.blur();
            }
          }}
          onInput={(e) => {
            e.target.style.height = 'auto';
            e.target.style.height = `${e.target.scrollHeight}px`;
          }}
          rows="1"
          wrap="soft"
        />
      )}
      
    </div>
  );
}

export default CheckboxField;
